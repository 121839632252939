import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import convertSlug from "../components/Utils/slug";
import { set_all_prod_category } from "../redux/actions/home";
import {
  get_producto,
  get_all_producto,
  limpiar_array_producto,
} from "../redux/actions/producto";
import classNames from "classnames";
import { useSearchTerm } from "../hooks/useSearchTerm";
import useFacebookPixel from "../modules/shared/ui/hooks/facebook-pixel/use-facebook-pixel";
const BuscadorPorCategoria = ({
  categoriaId,
  subCategoriaId,
  categorias,
  sucursal,
  get_producto,
  get_all_producto,
  limpiar_array_producto,
  set_all_prod_category,
  showLabel = true,
}) => {
  const pixel = useFacebookPixel();

  const {
    searchTerm: search,
    updateSearchTerm,
    deleteSearchTerm,
  } = useSearchTerm();
  const [filtro, setFiltro] = useState(search || " ");
  const { ciudadp, sucursalp } = useParams();
  const navigate = useNavigate();

  const getIdCategoria = useCallback(
    (slug) => {
      let resp = null;
      for (let item of categorias) {
        if (convertSlug.data_slug(item.Descripcion) === slug) {
          resp = item.IdCategoria;
          break;
        }
      }
      return resp;
    },
    [categorias]
  );

  const getIdsubctegoria = useCallback(
    (categoriaId, subcategoriaSlug) => {
      const categoriaEncontrada = categorias?.find(
        (categoria) => categoria.IdCategoria === categoriaId
      );
      const subCategoriaEncontrada = categoriaEncontrada?.SubCategorias.find(
        (categoria) =>
          convertSlug.data_slug(categoria.Descripcion) === subcategoriaSlug
      );
      return subCategoriaEncontrada?.IdSubcategoria;
    },
    [categorias]
  );

  const categoria = useMemo(() => {
    let resp = null;
    for (let item of categorias) {
      if (convertSlug.data_slug(item.Descripcion) === categoriaId) {
        resp = item;
        break;
      }
    }
    return resp;
  }, [categoriaId, categorias]);

  useEffect(() => {
    if (!search) {
      setFiltro("");
    }
  }, [search]);

  const cargar_productos = async (datos) => {
    if (!subCategoriaId) {
      await get_all_producto(datos, 1);
    } else {
      await get_producto(datos, 1);
    }
  };

  const obtenerProductos = async () => {
    let categId = await getIdCategoria(categoriaId);
    let subcatId = await getIdsubctegoria(categId, subCategoriaId);
    setTimeout(() => {
      let IdSubcategoria = subCategoriaId ? Number(subcatId) : null;

      let datos = {
        IdMarket: sucursal.IdMarket,
        IdLocatorio: sucursal.IdSucursal,
        IdCategoria: categId,
        IdSubcategoria: IdSubcategoria,
        texto_filtro: filtro,
      };
      cargar_productos(datos);
    }, 800);
  };

  const enter_opcion = (event) => {
    event.preventDefault();

    limpiar_array_producto();
    // obtenerProductos();
    if (filtro) {
      updateSearchTerm(filtro);
      if(pixel) {
        pixel.trackEvent("Search", {
          search_string: filtro.trim(),
          currency: "BOB",
        });
      }
    } else {
      deleteSearchTerm();
    }
    
  };
  const handleChange = (event) => {
    const { value } = event.target;
    save_text_filtro(event.target.value);
    setFiltro(value);
  };
  const save_text_filtro = (text) => {
    localStorage.setItem("filter_g", text);
  };
  return (
    <div className="w-100">
      <form
        onSubmit={enter_opcion}
        className="relative flex w-full flex-wrap items-stretch "
      >
        {showLabel && (
          <div className="z-10 absolute left-0 flex items-center h-full bg-hiperorange rounded-l-lg  p-2 gap-2">
            <div className="w-28 text-white text-xs leading-none font-bold truncate h-fit mt-1">
              {categoria?.Descripcion}
            </div>
            <button
              type="button"
              className="w-5 h-5 flex items-center justify-center bg-white hover:bg-gray-300 text-hiperorange rounded-full"
              onClick={() => {
                const datos = {
                  IdSubcategoria: categoria.SubCategorias[0].IdSubcategoria,
                  IdCategoria: categoria.IdCategoria,
                  todo: true,
                };
                set_all_prod_category(datos);

                navigate(
                  `/${ciudadp}/${sucursalp}/busqueda-producto/${
                    !!filtro ? `?s=${filtro.trim()}` : ""
                  }`
                );
              }}
            >
              <FontAwesomeIcon size="xs" icon={faX} />
            </button>
          </div>
        )}
        <input
          type="text"
          value={filtro}
          onChange={(e) => handleChange(e)}
          placeholder="¿QUÉ ESTAS BUSCANDO?"
          className={classNames(
            "w-100 py-2 placeholder-slate-400 text-slate-600 relative  bg-gray-200 rounded-lg text-sm border-1 border-gray-500  focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange pr-11",
            {
              "pl-40": showLabel,
              "pl-2": !showLabel,
            }
          )}
        />
        <button
          onClick={enter_opcion}
          type="submit"
          className="z-10 absolute right-0 flex items-center justify-center w-8 h-full bg-hiperorange hover:bg-hiperorange_hover rounded-r-lg text-white"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categorias: state.Home.categorias,
  sucursal: state.BranchOffice.sucursal,
  productos_peticion: state.Producto.productos_peticion,
});

export default connect(mapStateToProps, {
  limpiar_array_producto,
  get_all_producto,
  get_producto,
  set_all_prod_category,
})(BuscadorPorCategoria);
