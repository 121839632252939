// src/hooks/useFacebookPixel.ts
import { useEffect, useState } from "react";
import { FacebookPixel } from "react-use-facebook-pixel";
const PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
// to prevent pixel reinitialization on every rerender
let facebookPixelSingleton: FacebookPixel | null = null;

const useFacebookPixel = () => {
  const [facebookPixel, setFacebookPixel] = useState<FacebookPixel | null>(
    null
  );

  useEffect(() => {
    if (!facebookPixelSingleton) {
      const initializeFacebookPixel = async () => {
        const pixel = new FacebookPixel({
          pixelID: PIXEL_ID || "",
          debug: process.env.REACT_APP_ENVIRONMENT !== "production",
        });

        pixel.init({});
        pixel.trackEvent("PageView");

        facebookPixelSingleton = pixel;
        setFacebookPixel(pixel);
      };

      initializeFacebookPixel();
    } else {
      if (!facebookPixel) {
        setFacebookPixel(facebookPixelSingleton);
      }
    }
  }, []);

  return facebookPixel;
};

export default useFacebookPixel;
