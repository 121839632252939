import React, { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import convertSlug from "./slug";

import {
  store_carrito,
  limpiar_solo_carrito,
  get_carrito_por_sucursal,
} from "../../redux/actions/carrito";
import {
  get_ciudades,
  get_sucursales,
  get_sucursales_cerca,
  set_sucursal,
  set_tipo_entrega,
} from "../../redux/actions/branch_office";
import {
  end_peticion_total,
  update_url_navigation,
} from "../../redux/actions/url_navegacion";
import { get_anonimo_token } from "../../redux/actions/auth";

import { connect } from "react-redux";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useGetSucursalesMasCercanaPorTipo } from "../../hooks/useGetSucursalesMasCercanaPorTipo";
import {
  DEFAULT_STORE_BY_TIPO_SERVICIO,
  useGetSucursalTipoServicioBySlug,
} from "../../hooks/useGetSucursalTipoServicioBySlug";
import { useGetBackLink } from "../../hooks/useGetBackLink";

const ProtectedRoute = ({
  canAtivate,
  branch1,
  redirectPath = "/ubicacion",
  get_ciudades,
  ciudades,
  get_sucursales,
  get_sucursales_cerca,
  sucursales,
  user_session_active,
  set_sucursal,
  get_anonimo_token,
  set_tipo_entrega,
  update_url_navigation,
  end_peticion_total,
  branch_offices,
  store_carrito,
  carritos,
  producto_delete,
  limpiar_solo_carrito,
  get_carrito_por_sucursal,
}) => {
  const { getItem } = useLocalStorage();
  const params = useParams();
  const {
    ciudadp,
    sucursalp,
    productoId,
    productoSlug,
    IdCategoria,
    subcategoriaId,
    HashListaCompra,
    SeccionId,
    IdListaCompra,
  } = params;
  const { getSucursalesMasCercanaPorTipo } =
    useGetSucursalesMasCercanaPorTipo();
  const { getSucursalTipoServicioBySlug } = useGetSucursalTipoServicioBySlug();
  const { backLink } = useGetBackLink();
  const [searchParams] = useSearchParams();
  const lc = searchParams.get("lc");
  const redireccionar = searchParams.get("r");
  const searchTerm = searchParams.get("s");
  const abrirCarritoTerm = searchParams.get("abrir-carrito");

  const [url_navigation, setUrl_navigation] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const direccion = getItem("direccion");
  const sucursal = getItem("sucursal");
  const tipo_entrega = getItem("tipo_entrega");
  const [state_pase, setState_pase] = useState(false);

  const compartirListaLink = useMemo(() => {
    let link = "";
    if (lc) {
      link += `/lista-compra-compartida/${lc}`;
      if (abrirCarritoTerm) link += `/?abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [lc, abrirCarritoTerm]);

  const listaCompraCompartidaLink = useMemo(() => {
    let link = "";
    if (HashListaCompra) {
      link += `/lista-compra-compartida/${HashListaCompra}`;
      if (abrirCarritoTerm) link += `/?abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [HashListaCompra, abrirCarritoTerm]);

  const listaCompraLink = useMemo(() => {
    let link = "";
    if (IdListaCompra) {
      link += `/detalle-lista-compra/${IdListaCompra}`;
      if (abrirCarritoTerm) link += `/?abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [IdListaCompra, abrirCarritoTerm]);

  const productoLink = useMemo(() => {
    let link = "";
    if (productoId && productoSlug) {
      link += `/producto/${productoId}/${productoSlug}`;
      if (abrirCarritoTerm) link += `/?abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [productoId, productoSlug, abrirCarritoTerm]);

  const buscarLink = useMemo(() => {
    let link = "";
    if (!IdCategoria && !subcategoriaId && searchTerm) {
      link += `/busqueda-producto/?s=${searchTerm}`;
      if (abrirCarritoTerm) link += `&abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [IdCategoria, searchTerm, subcategoriaId, abrirCarritoTerm]);

  const categoriaLink = useMemo(() => {
    let link = "";
    if (IdCategoria) {
      link += `/categoria/${IdCategoria}`;
      if (subcategoriaId) link += `/${subcategoriaId}`;
      if (searchTerm) {
        link += `/?s=${searchTerm}`;
        if (abrirCarritoTerm) link += `&abrir-carrito=${abrirCarritoTerm}`;
      } else if (abrirCarritoTerm)
        link += `/?abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [IdCategoria, subcategoriaId, searchTerm, abrirCarritoTerm]);

  const seccionLink = useMemo(() => {
    let link = "";
    if (SeccionId) {
      link += `/seccion/${SeccionId}`;
      if (abrirCarritoTerm) link += `/?abrir-carrito=${abrirCarritoTerm}`;
    }

    return link;
  }, [SeccionId, abrirCarritoTerm]);

  useEffect(() => {
    if (!token) {
      fetchData();
    } else {
      fetchDataCity();
    }
  }, [token]);

  useEffect(() => {
    if (ciudades?.length > 0 && branch_offices?.length > 0) {
      if (!sucursal) {
        sesionParamOnload();
      } else {
        if (!ciudadp && !sucursalp) {
          if (!direccion) {
            sesionParamOnload();
          } else {
            sesionParamOnloadPorDireccion();
          }
        } else {
          if (direccion && redireccionar === "1") {
            sesionParamOnloadPorDireccion();
          } else if (
            convertSlug.data_slug(sucursal.Descripcion) !== sucursalp
          ) {
            sesionParamOnloadSucursal(sucursalp);
          }
        }
      }
    }
  }, [
    ciudades,
    branch_offices,
    ciudadp,
    sucursalp,
    sucursal,
    direccion,
    redireccionar,
  ]);
  // ciudades,sucursales
  useEffect(() => {
    if (url_navigation) {
      redirectSesion();
    }
  }, [url_navigation]);

  const fetchData = async () => {
    await get_anonimo_token();
    await get_ciudades();
    await get_sucursales_cerca(direccion);
  };
  const fetchDataCity = async () => {
    await get_ciudades();
    await get_sucursales_cerca(direccion);
  };

  const sesionIniciado = () => {
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  // USUARIO ACTIVO SESION INICIADO
  const sesionParamOnload = async () => {
    let previousSucursal = sucursal;
    if (!previousSucursal) {
      previousSucursal = await getItemSucursal(
        DEFAULT_STORE_BY_TIPO_SERVICIO.SUPERMERCADO ||
          "hipermaxi-roca-y-coronado"
      );
    }
    let ciudad = await ciudadSeleccionado(previousSucursal.idRegion);
    let sucurs = await sucursalSeleccionado(previousSucursal.IdSucursal);
    // let sucurs= sucursal;
    let sucursal_slug = await convertSlug.data_slug(sucurs.Descripcion);
    let ciudad_slug = await convertSlug.data_slug(ciudad);
    let resp = ciudad_slug + " " + sucursal_slug;
    let url_parameters = await convertSlug.data_barra(resp);
    if (compartirListaLink) {
      url_parameters += compartirListaLink;
    } else if (productoLink) {
      url_parameters += productoLink;
    } else if (buscarLink) {
      url_parameters += buscarLink;
    } else if (categoriaLink) {
      url_parameters += categoriaLink;
    } else if (seccionLink) {
      url_parameters += seccionLink;
    } else if (listaCompraCompartidaLink) {
      url_parameters += listaCompraCompartidaLink;
    } else if (listaCompraLink) {
      url_parameters += listaCompraLink;
    } else if (abrirCarritoTerm) {
      url_parameters += `/?abrir-carrito=${abrirCarritoTerm}`;
    }
    if (previousSucursal.TipoEntregas.length > 0) {
      let tipoEntrega =
        previousSucursal.TipoEntregas.find(
          (tipoEntrega) => tipoEntrega.Id === 2
        ) || previousSucursal.TipoEntregas[0];
      await set_tipo_entrega(tipoEntrega);
    }
    await set_sucursal(previousSucursal);
    await register_url(previousSucursal);
    setUrl_navigation(url_parameters);
  };

  const sesionParamOnloadSucursal = async (sucursalp) => {
    let previousSucursal = await getItemSucursal(sucursalp);
    if (!previousSucursal) {
      previousSucursal = await getItemSucursal(
        DEFAULT_STORE_BY_TIPO_SERVICIO.SUPERMERCADO ||
          "hipermaxi-roca-y-coronado"
      );
    }
    let ciudad = await ciudadSeleccionado(previousSucursal.idRegion);
    let sucurs = await sucursalSeleccionado(previousSucursal.IdSucursal);
    // let sucurs= sucursal;
    let sucursal_slug = await convertSlug.data_slug(sucurs.Descripcion);
    let ciudad_slug = await convertSlug.data_slug(ciudad);
    let resp = ciudad_slug + " " + sucursal_slug;
    let url_parameters = await convertSlug.data_barra(resp);
    if (compartirListaLink) {
      url_parameters += compartirListaLink;
    } else if (productoLink) {
      url_parameters += productoLink;
    } else if (buscarLink) {
      url_parameters += buscarLink;
    } else if (categoriaLink) {
      url_parameters += categoriaLink;
    } else if (seccionLink) {
      url_parameters += seccionLink;
    } else if (listaCompraCompartidaLink) {
      url_parameters += listaCompraCompartidaLink;
    } else if (listaCompraLink) {
      url_parameters += listaCompraLink;
    } else if (abrirCarritoTerm) {
      url_parameters += `/?abrir-carrito=${abrirCarritoTerm}`;
    }
    if (previousSucursal.TipoEntregas.length > 0) {
      let tipoEntrega =
        previousSucursal.TipoEntregas.find(
          (tipoEntrega) => tipoEntrega.Id === 2
        ) || previousSucursal.TipoEntregas[0];
      await set_tipo_entrega(tipoEntrega);
    }

    if (carritos && carritos.length > 0) {
      store_carrito(
        sucursal,
        tipo_entrega,
        direccion,
        carritos,
        producto_delete,
        false
      );
      limpiar_solo_carrito();
    }

    await set_sucursal(previousSucursal);
    get_carrito_por_sucursal(previousSucursal);
    await register_url(previousSucursal);
    setUrl_navigation(url_parameters);
  };

  const sesionParamOnloadPorDireccion = async () => {
    const previousSucursalTipoServico =
      getSucursalTipoServicioBySlug(sucursalp);
    const defaultSucursalSlug =
      DEFAULT_STORE_BY_TIPO_SERVICIO[previousSucursalTipoServico];
    let previousSucursal = getSucursalesMasCercanaPorTipo(
      sucursales,
      previousSucursalTipoServico
    );
    if (!previousSucursal) {
      previousSucursal = await getItemSucursal(
        defaultSucursalSlug || "hipermaxi-roca-y-coronado"
      );
    }
    let ciudad = await ciudadSeleccionado(previousSucursal.idRegion);
    let sucurs = await sucursalSeleccionado(previousSucursal.IdSucursal);
    // let sucurs= sucursal;
    let sucursal_slug = await convertSlug.data_slug(sucurs.Descripcion);
    let ciudad_slug = await convertSlug.data_slug(ciudad);
    let resp = ciudad_slug + " " + sucursal_slug;
    let url_parameters = await convertSlug.data_barra(resp);

    if (compartirListaLink) {
      url_parameters += compartirListaLink;
    } else if (productoLink) {
      url_parameters += productoLink;
    } else if (buscarLink) {
      url_parameters += buscarLink;
    } else if (categoriaLink) {
      url_parameters += categoriaLink;
    } else if (seccionLink) {
      url_parameters += seccionLink;
    } else if (listaCompraCompartidaLink) {
      url_parameters += listaCompraCompartidaLink;
    } else if (listaCompraLink) {
      url_parameters += listaCompraLink;
    } else if (abrirCarritoTerm) {
      url_parameters += `/?abrir-carrito=${abrirCarritoTerm}`;
    }
    if (previousSucursal.TipoEntregas.length > 0) {
      let tipoEntrega =
        previousSucursal.TipoEntregas.find(
          (tipoEntrega) => tipoEntrega.Id === 2
        ) || previousSucursal.TipoEntregas[0];
      await set_tipo_entrega(tipoEntrega);
    }
    await set_sucursal(previousSucursal);
    await register_url(previousSucursal);
    setUrl_navigation(url_parameters);
  };

  const register_url = async (sucursal) => {
    let ciudad = await ciudadSeleccionado(sucursal.idRegion);
    let sucurs = await sucursalSeleccionado(sucursal.IdSucursal);
    let sucursal_slug = await convertSlug.data_slug(sucurs.Descripcion);
    let ciudad_slug = await convertSlug.data_slug(ciudad);
    let resp = ciudad_slug + " " + sucursal_slug;
    let url_parameters = await convertSlug.data_barra(resp);
    setUrl_navigation(url_parameters);
    await update_url_navigation({
      url_parameters,
      id_sucursal: sucursal.IdSucursal,
      id_ciudad: sucursal.idRegion,
    });
  };

  const getItemSucursal = async (slug) => {
    let item_slected = null;
    branch_offices.forEach((sucursal) => {
      if (!item_slected) {
        item_slected = sucursal.Locatarios.find(
          (pre) => convertSlug.data_slug(pre.Descripcion) === slug
        );
      }
    });

    return item_slected;
  };

  const sucursalSeleccionado = async (IdSucursal) => {
    let selected_suc = null;
    branch_offices.forEach((sucursal) => {
      if (!selected_suc) {
        selected_suc = sucursal.Locatarios.find(
          (pre) => pre.IdSucursal === IdSucursal
        );
      }
    });
    return selected_suc;
  };

  const ciudadSeleccionado = (id_region) => {
    let seleccionado = ciudades.find(
      (pre) => parseInt(pre.IdRegion) === parseInt(id_region)
    );
    return seleccionado.Nombre;
  };

  const verificar = (objDato) => {
    if (!inactiveUserSession()) {
      if (Object.keys(objDato).length != 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const activeUserSession = () => {
    if (
      sesionIniciado() &&
      verificar(sucursal) &&
      verificar(tipo_entrega) &&
      verifyCiudades() &&
      verifySucursales()
    ) {
      return true;
    }
    return false;
  };

  const inactiveUserSession = () => {
    if (sucursal === undefined || tipo_entrega === undefined) {
      return true;
    }
    return false;
  };

  const verifyCiudades = () => {
    return ciudades.length > 0 ? true : false;
  };

  const verifySucursales = () => {
    return branch_offices.length > 0 ? true : false;
  };

  const verifyParametroUrl = () => {
    if (sucursalp && ciudadp) {
      return true;
    }
    return false;
  };

  const redirectSesion = () => {
    if (url_navigation) {
      setState_pase(true);
      navigate("/" + url_navigation, {
        replace: true,
      });
      return <Outlet />;
    } else if (verifyParametroUrl()) {
      setState_pase(true);
      return <Outlet />;
    }
  };

  if (state_pase || activeUserSession()) {
    return <Outlet />;
  }
};
const mapStateToProps = (state) => ({
  branch_offices: state.BranchOffice.branch_offices,
  ciudades: state.BranchOffice.ciudades,
  sucursales: state.BranchOffice.sucursales,
  user_session_active: state.UrlNavegacion.user_session_active,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
});

export default connect(mapStateToProps, {
  get_ciudades,
  get_sucursales,
  set_sucursal,
  get_sucursales_cerca,
  get_anonimo_token,
  set_tipo_entrega,
  update_url_navigation,
  end_peticion_total,
  store_carrito,
  limpiar_solo_carrito,
  get_carrito_por_sucursal,
})(ProtectedRoute);
