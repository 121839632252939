import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonClose from "../../../widgets/button_close";
import {
  faFacebook,
  faTelegram,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import CreateQr from "../../compras/CreateQr";

const ContenidoCompartir = ({ listaCompra, ps_closeModal }) => {
  const [qrImageUrl, setQrImageUrl] = useState("");
  const [qrDescripcion, setQrDescripcion] = useState("");

  const [imageCopied, setImageCopied] = useState(false);
  const [textCopied, setTextCopied] = useState(false);

  const [permiso, setPermiso] = useState(false);

  const { ClipboardItem } = window;
  useEffect(() => {
    verificarPermiso();
  }, []);

  function onloadQrUrl(QrImage) {
    setQrImageUrl(QrImage);
  }
  function onloadQrDescrip(description) {
    setQrDescripcion(description);
  }
  async function askWritePermission() {
    try {
      const { state } = await navigator.permissions.query({
        name: "clipboard-write",
      });
      return state === "granted";
    } catch (error) {
      return false;
    }
  }

  const verificarPermiso = async () => {
    const canWriteToClip = await askWritePermission();
    if (canWriteToClip) {
      setPermiso(true);
    } else {
      setPermiso(false);
    }
  };

  const copyImage = async () => {
    const canWriteToClipboard = await askWritePermission();
    if (canWriteToClipboard) {
      const img = qrImageUrl;
      const blob = await fetch(img).then((r) => r.blob());
      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard
        .write([item])
        .then(() => {
          setImageCopied(true);
        })
        .catch((err) => {
          setImageCopied(false);
        });

      //
    } else {
    }
  };

  const copyText = async (text) => {
    const canWriteToClipboard = await askWritePermission();
    if (canWriteToClipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setTextCopied(true);
        })
        .catch((err) => {
          setTextCopied(false);
        });

      //
    } else {
    }
  };

  return (
    <>
      <div className="bg-white rounded-lg overflow-hidden w-full h-full max-h-[550px] md:max-h-[550px]">
        <div className="flex flex-col h-full">
          <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
            <h2 className="p-0 m-0 title_carrito_responsivo">Compartir</h2>
            <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={() => ps_closeModal()}
              />
            </div>
          </div>
          <div className="bg-white overflow-y-auto flex flex-col h-full gap-2 p-3">
            <div className="grid grid-rows-1 gap-1 p-3">
              <span className="text-center font-bold">
                Comparte tu lista de compra
              </span>
              <div className="flex justify-center">
                <div className="px-2 py-1 rounded-lg  mt-2 max-w-xs overflow-hidden text-center text-black">
                  <span className="break-words">{listaCompra.Descripcion}</span>
                </div>
              </div>
              <div className="flex justify-center">
                <CreateQr
                  ps_formData={listaCompra}
                  ps_onloadQrUrl={onloadQrUrl}
                  ps_onloadQrDescrip={onloadQrDescrip}
                />
              </div>
              <div>
                <div className="flex flex-column justify-center gap-2">
                  {permiso ? (
                    // faFlipboard
                    <button
                      onClick={() => {
                        copyImage();
                        const timer = setTimeout(() => {
                          setImageCopied(false);
                          clearTimeout(timer);
                        }, [5000]);
                      }}
                      className="w-full bg-hiperorange hover:bg-hiperorange_hover rounded-lg border-hiperorange py-2  items-center text-center text-white "
                    >
                      {imageCopied ? (
                        <span className="text-white">QR Copiado</span>
                      ) : (
                        "Copiar QR"
                      )}
                    </button>
                  ) : (
                    <small>
                      {" "}
                      <i>hacer clic derecho sobre la imagen y copiar </i>{" "}
                    </small>
                  )}

                  <a
                    href={qrImageUrl}
                    download={qrDescripcion}
                    className="w-full bg-hiperorange hover:bg-hiperorange_hover rounded-lg border-hiperorange py-2  items-center text-center text-white "
                  >
                    Descargar QR
                  </a>

                  {permiso && (
                    <button
                      onClick={() => {
                        copyText(listaCompra.HashListaCompra);
                        const timer = setTimeout(() => {
                          setTextCopied(false);
                          clearTimeout(timer);
                        }, [5000]);
                      }}
                      className="w-full bg-hiperorange hover:bg-hiperorange_hover rounded-lg border-hiperorange py-2  items-center text-center text-white "
                    >
                      {textCopied ? (
                        <span className="text-white">Texto copiado</span>
                      ) : (
                        "Copiar link"
                      )}
                    </button>
                  )}
                </div>

                <Popover className="relative inline ">
                  {({ open }) => (
                    <>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute mt-2 w-full z-10">
                          <div className="bg-white px-4 py-4 border border-gray-500 shadow-2xl">
                            <div className="flex justif-center gap-2 flex justify-center">
                              <div className="text-center">
                                <a
                                  href="https://www.facebook.com/sharer/sharer.php?u=https://espai.es"
                                  target="_blank"
                                >
                                  <FontAwesomeIcon
                                    icon={faFacebook}
                                    style={{ height: "35px" }}
                                    className="text-zinc-400"
                                  />
                                </a>
                              </div>
                              <div className="text-center">
                                <a
                                  href={`https://api.whatsapp.com/send?text=${qrImageUrl}`}
                                  target="_blank"
                                >
                                  <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    style={{ height: "35px" }}
                                    className="text-zinc-400"
                                  />
                                </a>
                              </div>
                              <div className="text-center">
                                <a
                                  href="https://telegram.me/share/url?url=DIRECCION_URL&text=TEXTO"
                                  target="_blank"
                                >
                                  <FontAwesomeIcon
                                    icon={faTelegram}
                                    style={{ height: "35px" }}
                                    className="text-zinc-400"
                                  />
                                </a>
                              </div>
                              <div className="text-center">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=[direccion]">
                                  <FontAwesomeIcon
                                    icon={faLinkedin}
                                    style={{ height: "35px" }}
                                    className="text-zinc-400"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="mt-4">
                              <button className="bg-hiperblue w-full border-2 border-hiperblue text-white rounded-lg">
                                Cancelar
                              </button>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Shopping_list.loading,
});

export default connect(mapStateToProps, {})(ContenidoCompartir);
