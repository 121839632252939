import Layout from "../hocs/Layout";
import { React, useEffect, useMemo, useState } from "react";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  get_producto_item,
  limpiar_item_producto,
} from "../redux/actions/producto";
import {
  actualizar_item_carrito,
  add_al_carrito,
  dec_al_carrito,
  get_carrito_por_sucursal,
  inc_al_carrito,
  limpiar_solo_carrito,
  set_cambio_detalle_carrito,
  store_carrito,
  store_carrito_data,
  update_list_carrito_compras,
} from "../redux/actions/carrito";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faCircleXmark,
  faHeart,
  faMinus,
  faPlus,
  faSquareMinus,
  faSquarePlus,
  faTag,
  faTrashCan,
  faAngleDown,
  faAngleUp,
  faCartCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import FullScreenImage from "./../widgets/fullScreenImage";
import { useNavigate } from "react-router-dom";
import { set_all_prod_category } from "../redux/actions/home";
import Favorito from "../widgets/favoritoMycart";
import convertSlug from "../components/Utils/slug";
import "../../src/assets/styles/seccionResponsive.scss";
import Alert from "../components/Swalert";
import { setSwNoAlert } from "../redux/actions/swalert";
import ButtonText from "../widgets/button_text";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../src/assets/styles/seccionResponsive.scss";
import "../../src/assets/styles/seccionResponsiveSwiper.scss";
import RegistroInit from "../components/modal/registroUser/registro_init";
import useScreenSize from "../widgets/useScreenSize";
import Sucursal from "../components/modal/sucursal";
import { EasyZoomOnMove } from "easy-magnify";
import classNames from "classnames";
import { useThousandSeparator } from "../hooks/useThousandSeparator";
import { Image } from "../components/image";
import { ZoomProvider } from "../components/ImageZoom/ZoomContext";
import ImageZoom from "../components/ImageZoom/ImageZoom";
import ZoomedImage from "../components/ImageZoom/ZoomedImage";
import { Helmet } from "react-helmet-async";
import ButtonClose from "../widgets/button_close";
import LoaderSpinner from "../widgets/loaderSpinner";
import { useIsDefaultStore } from "../hooks/useIsDefaultStore";
import { MensajeTelefonoProductoDisponible } from "./MensajeTelefonoProductoDisponible";
import useFacebookPixel from "../modules/shared/ui/hooks/facebook-pixel/use-facebook-pixel";
const customStylesModalSucursal = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    overflow: "hidden",
  },
};

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};
const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "100",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];

const style_favorito = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
  },
};

const InformacionProducto = ({
  setSwNoAlert,
  get_carrito_por_sucursal,
  list_carrito,
  store_carrito_data,
  get_producto_item,
  sucursal,
  producto,
  direccion,
  limpiar_solo_carrito,

  store_carrito,
  limpiar_item_producto,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,

  actualizar_item_carrito,
  tipo_entrega,

  set_all_prod_category,
  set_cambio_detalle_carrito,
  sucursales,
  profile,
  update_list_carrito_compras,
  isLoading: isLoadingProducto,
}) => {
  const pixel = useFacebookPixel();
  const { isDefaultStore } = useIsDefaultStore();
  const { numberFormatter } = useThousandSeparator();
  const [thumbsSwiperCarritoDesktop, setThumbsSwiperCarritoDesktop] =
    useState(null);
  const [thumbsSwiperCarritoMobile, setThumbsSwiperCarritoMobile] =
    useState(null);
  let subtitle;
  const { ciudadp, sucursalp, productoId, IdMarket, IdSucursal } = useParams();
  const [carrito, guardarCarrito] = useState([]);
  const [producto_delete, guardarProductoDelete] = useState([]);
  const [imagenes, guardarImagenes] = useState([]);

  const [pesos, guardarPesos] = useState([]);
  const [cantidad, guardarCantidad] = useState(0);
  const [monto, guardarMonto] = useState(0);
  const [hubo_cambios, setHuboCambios] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal_favorito, setModalFavorito] = useState(false);
  const startModal = () => setModal(true);
  const endModal = () => setModal(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
  const [url_navigation, setUrl_navigation] = useState(null);
  const [IdTipoEntrega, guardarIdTipoEntrega] = useState("");
  const [imagengrande, setImagengrande] = useState(null);
  const [index_imagen, setIndex_imagen] = useState(1);
  const [swiperRef, setSwiperRef] = useState(null);
  const { width, height } = useScreenSize();

  const openModalFavorito = () => setModalFavorito(true);
  const closeModalFavorito = () => setModalFavorito(false);
  const [openModalUserRegister, setOpenModalUserRegister] = useState(false);
  const [openModalSucursal, setOpenModalSucursal] = useState(false);

  const tieneCobertura = useMemo(() => {
    if (!direccion) return true;
    const sucursalEncontrada = sucursales?.find(
      (suc) => suc.IdMarket === sucursal.IdMarket
    );

    const locatarioEncontrado = sucursalEncontrada?.Locatarios.find(
      (locatario) => locatario.IdSucursal === sucursal.IdSucursal
    );
    return !!locatarioEncontrado;
  }, [sucursal, sucursales, direccion]);

  const handleOpenModalSucursal = () => {
    setOpenModalSucursal(true);
  };

  const handleCloseModalSucursal = () => {
    setOpenModalSucursal(false);
  };

  const closeFullScreen = () => {
    setShowFullScreen(false);
  };

  const actualizarAnchoPantalla = () => {
    setAnchoPantalla(window.innerWidth);
  };

  const total = (monto * cantidad).toFixed(2);

  const _es_pieza = () => {
    if (producto) {
      return producto.Fraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const _estimado = () => {
    let result = 0;
    if (_es_pieza()) {
      pesos.forEach((peso) => {
        result += peso.Piezas * peso.PesoAproxMax;
      });
    } else {
      result = cantidad;
    }
    return Number(result).toFixed(2);
  };

  const _ahorrado = () => {
    let resultado = 0;
    if (producto) {
      let precio_venta = producto.ConOferta
        ? producto.PrecioOriginal
        : producto.PrecioVenta;

      if (_es_pieza()) {
        let cantidad_pieza = _estimado();
        let aux = producto.ConOferta
          ? producto.PrecioOferta
          : producto.PrecioVenta;
        let total_sin_oferta = cantidad_pieza * producto.PrecioOriginal;
        let total_oferta = cantidad_pieza * aux;
        resultado = total_sin_oferta - total_oferta;
      } else {
        resultado = precio_venta * cantidad - total;
      }
    }

    return resultado.toFixed(2);
  };
  const ahorrado = _ahorrado();
  const _descuento = () => {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();

  const _cantidad_peso = () => {
    let result = cantidad * 1000;
    return result.toFixed(0) + "g";
  };

  const _total_estimado = () => {
    let result = 0;
    result = _es_pieza() ? monto * estimado : monto * cantidad;
    return result.toFixed(2);
  };
  const estimado = _estimado();
  const total_estimado = _total_estimado();
  const cantidad_peso = _cantidad_peso();
  const cargar_url_var = async () => {
    let resp = ciudadp + " " + sucursalp;
    let url_parameters = await convertSlug.data_barra(resp);
    setUrl_navigation(url_parameters);
  };
  useEffect(() => {
    setAnchoPantalla(width);
  }, [width]);

  useEffect(() => {
    if (list_carrito) {
      _guardarCarrito();
    } else {
      get_carrito_por_sucursal(sucursal);
    }
  }, [list_carrito]);
  useEffect(() => {
    get_producto_item(IdSucursal, productoId);
  }, [sucursales]);
  useEffect(() => {
    if (ciudadp != null && sucursalp != null) {
      cargar_url_var();
    }
  }, [ciudadp, sucursalp]);

  useEffect(() => {
    set_cambio_detalle_carrito(true);
    return () => {
      limpiar_item_producto();
      window.addEventListener("resize", actualizarAnchoPantalla);
    };
  }, []);

  useEffect(() => {
    if (producto) {
      if (_es_pieza()) {
        _peso_definido();
      }
    }
  }, [carrito]);

  useEffect(() => {
    let cantidad = 0;
    pesos.forEach((peso) => {
      cantidad += peso.Piezas;
    });
    guardarCantidad(cantidad);
  }, [pesos]);
  useEffect(() => {
    if (cantidad === 0) {
      if (hubo_cambios) {
        remove_al_carrito();
      }
    } else {
      if (hubo_cambios) {
        let pesos_aux = [];
        pesos.forEach((elem) => {
          if (elem.Piezas > 0) {
            pesos_aux.push(elem);
          }
        });
        actualizar_carrito_local(cantidad, pesos_aux);
      }
    }
  }, [cantidad]);

  useEffect(() => {
    if (direccion) {
      let params = {
        IdMarket: Number(IdMarket),
        IdSucursal: Number(IdSucursal),
        IdTipoEntrega: IdTipoEntrega,
        IdDireccion: direccion.Id,
      };

      localStorage.setItem(
        "cart-to-update",
        JSON.stringify({
          params,
          carrito,
          producto_delete,
        })
      );
    }
  }, [
    IdMarket,
    IdSucursal,
    IdTipoEntrega,
    carrito,
    direccion,
    producto_delete,
  ]);

  const remove_al_carrito = () => {
    guardarCarrito(
      carrito.filter((pd) => pd.IdProducto !== producto.IdProducto)
    );
    const check_item = producto_delete.find(
      (pd) => pd.IdProducto === producto.IdProducto
    );
    if (!check_item) {
      guardarProductoDelete([
        ...producto_delete,
        { IdProducto: producto.IdProducto },
      ]);
    }
  };

  const actualizar_carrito_local = (cantidad, pesos_aux) => {
    let producto_carrito = carrito.find(
      (prd) => prd.IdProducto === producto.IdProducto
    );
    if (producto_carrito) {
      guardarCarrito(
        carrito.map((ps) => {
          if (ps.IdProducto === producto.IdProducto) {
            return {
              ...ps,
              Cantidad: cantidad,
              Pesos: pesos_aux,
            };
          } else {
            return ps;
          }
        })
      );
    } else {
      const productoClonado = Object.assign({}, producto);
      productoClonado.Cantidad = cantidad;
      productoClonado.Pesos = pesos_aux;
      productoClonado.EsFraccionado = productoClonado.Fraccionado;
      guardarCarrito((cantidad) => [...carrito, productoClonado]);
      delete_producto();
    }
  };

  const delete_producto = () => {
    let check_delete = producto_delete.find(
      (prd) => prd.IdProducto === producto.IdProducto
    );
    if (check_delete) {
      guardarProductoDelete(
        producto_delete.filter((pd) => pd.IdProducto !== producto.IdProducto)
      );
    }
  };

  useEffect(() => {
    if (producto) {
      if (_es_pieza()) {
        _peso_definido();
      }
      let producto_carrito = carrito.find(
        (prd) => prd.IdProducto === producto.IdProducto
      );
      let monto_aux = producto.ConOferta
        ? producto.PrecioOferta
        : producto.PrecioVenta;
      guardarMonto(monto_aux);
      if (producto_carrito) {
        guardarCantidad(producto_carrito.Cantidad);
      }

      let fotos = [];
      if (producto.Fotos) {
        fotos = producto.Fotos;
      } else {
        let object = {
          orden: 1,
          Url: producto.UrlFoto,
        };
        fotos.push(object);
      }

      guardarImagenes(fotos);
    }
  }, [producto, carrito]);

  const add_carrito = () => {
    if (!verifyUserAutenticate()) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    setHuboCambios(true);
    let pesaje = 1;
    if (_es_peso()) {
      pesaje = 0.1;
    }
    guardarCantidad(Number(cantidad) + pesaje);
  };

  const _peso_definido = () => {
    const producto_carrito = carrito.find(
      (prd) => prd.IdProducto === producto.IdProducto
    );
    var protudctoCopia = { ...producto_carrito };

    let pesos_aux = set_piezas_carrito(protudctoCopia.Pesos);

    if (producto_carrito) {
      guardarCantidad(protudctoCopia.Cantidad);
    }
    guardarPesos(pesos_aux);
  };

  const actualizarDato = (peso, operacion) => {
    if (!verifyUserAutenticate()) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    setHuboCambios(true);
    if (operacion === "incremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso) {
            return {
              ...ps,
              Piezas: ps.Piezas + 1,
            };
          } else {
            return ps;
          }
        })
      );
    } else if (operacion === "decremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso && ps.Piezas >= 1) {
            return {
              ...ps,
              Piezas: ps.Piezas - 1,
            };
          } else {
            return ps;
          }
        })
      );
    }
  };

  const set_piezas_carrito = (pesos) => {
    producto.Pesos.forEach((peso) => {
      let peso_aux = null;
      if (pesos) {
        peso_aux = pesos.find((ps) => ps.IdPeso === peso.IdPeso);
      }
      if (peso_aux) {
        peso.Piezas = peso_aux.Piezas;
      } else {
        peso.Piezas = 0;
      }
    });
    return producto.Pesos;
  };

  const inputchangePeso = (cant_peso, peso) => {
    if (!verifyUserAutenticate()) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    guardarPesos(
      pesos.map((ps) => {
        if (ps.IdPeso === peso.IdPeso) {
          setHuboCambios(true);
          return {
            ...ps,
            Piezas: cant_peso,
          };
        } else {
          return ps;
        }
      })
    );
  };

  const _es_peso = () => {
    if (producto) {
      return producto.Fraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const abrirModal = () => {
    startModal(true);
  };

  const guardarPesoEnCarrito = (producto, cantidad) => {
    const prevCarrito = [...carrito];
    const productoToUpdateIndex = prevCarrito.findIndex(
      (productoCarrito) => productoCarrito.IdProducto === producto.IdProducto
    );
    if (productoToUpdateIndex >= 0) {
      const productoToUpdate = prevCarrito[productoToUpdateIndex];
      prevCarrito[productoToUpdateIndex] = {
        ...productoToUpdate,
        Cantidad: cantidad,
      };
    }

    guardarCarrito(prevCarrito);
  };

  const actualizar_item = (valor, operacion) => {
    let resultado = 0;
    resultado =
      operacion == "incremento"
        ? Number((cantidad + valor).toFixed(1))
        : Number((cantidad - valor).toFixed(1));
    if (resultado < 0) {
      return;
    }
    if (operacion == "incremento" && cantidad == 0) {
      guardarCantidad(resultado);
      add_al_carrito(producto, resultado);
      guardarPesoEnCarrito(producto, resultado);
    } else if (operacion == "decremento" && cantidad == valor) {
      guardarCantidad(resultado);
      remove_al_carrito(producto);
      guardarPesoEnCarrito(producto, resultado);
    } else {
      guardarCantidad(resultado);
      actualizar_item_carrito(producto, resultado);
      guardarPesoEnCarrito(producto, resultado);
    }
  };

  const set_peso_pieza = (peso) => {
    let resultado = peso.peso / 1000;
    guardarCantidad(resultado);
    actualizar_item_carrito(producto, resultado);
  };

  function reducir_carrito() {
    if (!verifyUserAutenticate()) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    setHuboCambios(true);
    if (cantidad === 1) {
      guardarCantidad(cantidad - 1);
      remove_al_carrito(producto);
    } else if (cantidad >= 1) {
      guardarCantidad(cantidad - 1);
    }
  }

  function inputchange(e) {
    if (!verifyUserAutenticate()) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    const esValido = e.target.validity.valid;
    if (esValido) {
      let cant = e.target.value;
      if (producto.Fraccionado & !producto.PesoDefinido) {
      } else {
        guardarCantidad(cant);
        if (cant > 0) {
          add_al_carrito(producto, Number(cant));
        } else {
          remove_al_carrito(producto);
        }
      }
    }
  }

  function afterOpenModal() {
    setTimeout(function () {
      subtitle.style.color = "blue";
    }, 2000);
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function show_subcategoria(cat_id, subcat_id) {
    navigate("/" + url_navigation + `/categoria/${cat_id}/${subcat_id}/`);
  }

  function show_categoria(cat_id) {
    navigate("/" + url_navigation + `/categoria/${cat_id}`);
  }

  function formatNumber(value) {
    return numberFormatter.format(value);
  }

  const fueraFoco = () => {};

  function _guardarCarrito() {
    if (list_carrito) {
      if (sucursal.IdSucursal === Number(IdSucursal)) {
        // limpiar_solo_carrito();
      }
      list_carrito.SubPedidos.forEach((subpedido) => {
        if (subpedido.Sala.Id === Number(IdSucursal)) {
          guardarIdTipoEntrega(subpedido.IdTipoEntrega);
          guardarCarrito(subpedido.Productos);
        }
      });
    }
  }

  function volverAtras() {
    // if (direccion) {
    //   let params = {
    //     IdMarket: IdMarket,
    //     IdSucursal: IdSucursal,
    //     IdTipoEntrega: IdTipoEntrega,
    //     IdDireccion: direccion.Id,
    //   };
    //   if (carrito.length > 0) {
    //     store_carrito_data(params, carrito, producto_delete);
    //   }
    // }

    // let objet3 = {
    //   type: "modal",
    //   type_modal: true,
    //   type_title: "carrito",
    //   ruta: "back",
    // };
    // setSwNoAlert(objet3);
    window.history.back();
  }

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex + 1; // Adjust for 0-based indexing
    setIndex_imagen(currentSlideIndex);
  };
  const selec_imagen = (foto, index) => {
    setImagengrande(foto);
    swiperRef.slideTo(index, 0);
  };

  const show_botones = () => {
    if (!verifyUserAutenticate()) {
      abrir_modal_registro();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    add_carrito();
    let cantidad = 1;
    if (_es_peso()) {
      cantidad = 0.1;
      //  actualizarDato(peso, "incremento");
    }
    pixel.trackEvent("AddToCart", {
      content_name: producto.Descripcion, // Nombre del producto
      content_ids: [producto.IdProducto], // ID del producto
      content_type: "product", // Tipo de contenido
      value:
        (producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta) *
        cantidad, // Valor del producto
      currency: "BOB", // Moneda
    });
  };

  useEffect(() => {
    if (imagenes.length > 0) {
      setImagengrande(imagenes[0].Url);
    }
  }, [imagenes]);

  const verifyUserAutenticate = () => {
    if (profile.Telefono !== "") {
      return true;
    }
    return false;
  };
  useEffect(() => {
    // Perform some setup actions
    return () => {
      // This is the cleanup function
      // It will be called when the component is unmounted
      set_cambio_detalle_carrito(false);
    };
  }, []); // The empty array ensures this effect runs once on mount and once on unmount

  const show_ventana_favorito = () => {
    if (verifyUserAutenticate()) {
      openModalFavorito();
    } else {
      abrir_modal_registro();
    }
  };

  const abrir_modal_registro = () => {
    setOpenModalUserRegister(true);
  };
  const cerrarVentana = (valor) => {
    setOpenModalUserRegister(false);
  };

  useEffect(() => {
    // for handle if thumb is destroyed or not
    // because when we redirect to same page (like using i18n)
    // we must set as null
    if (thumbsSwiperCarritoDesktop?.destroyed) {
      setThumbsSwiperCarritoDesktop(null);
    }

    if (thumbsSwiperCarritoMobile?.destroyed) {
      setThumbsSwiperCarritoMobile(null);
    }

    return () => {
      setThumbsSwiperCarritoMobile(null);
      setThumbsSwiperCarritoDesktop(null);
    };
  }, []);

  const isUserAuthenticated = useMemo(() => {
    if (!profile) return null;
    return profile?.Telefono != null && profile?.Telefono !== "";
  }, [profile]);

  const isLoading = isLoadingProducto || isUserAuthenticated === null;

  return (
    <>
      <Helmet>
        <title>{`${producto?.Descripcion || ""} | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        <meta
          name="description"
          content={`Realiza tu compra en línea de ${
            producto?.Descripcion || ""
          } en ${
            !sucursal || isDefaultStore
              ? "tu sucursal Hipermaxi más cercana"
              : `la sucursal ${sucursal.Descripcion}`
          }. Mediante unos simples pasos envíalo al carrito de compra, decide cómo pagar y recíbelo en cuestión de minutos.`}
        />
        {/* {breadcrumbListJSONLdScript}
          {itemListJSONLdScript} */}
      </Helmet>
      <ZoomProvider>
        <Layout>
          {producto && (
            <>
              {/* <div className="flex mx-4 justify-start p-2 ">
            <button onClick={() => volverAtras()} className="cursor-pointer">
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ fontSize: "1.5rem" }}
              />
            </button>
          </div> */}
              <Alert></Alert>
              {openModalUserRegister && (
                <RegistroInit setCloseModal={cerrarVentana}></RegistroInit>
              )}
              <div className="relative flex-grow md:mt-4">
                <div className="mx-auto max-w-[1920px] container_main">
                  <div className="flex pb-4 pt-1">
                    <div className="w-full container_prod">
                      {isLoading ? (
                        <div className="flex justify-center text-black text-center pb-2">
                          <LoaderSpinner bool={true} />
                        </div>
                      ) : (
                        <>
                          {producto ? (
                            <div className="container">
                              <div className="header-responsive-categorias   ">
                                <div>
                                  <div className="flex justify-start">
                                    <Link
                                      className="md:px-2 md:py-2.5 bg-gray-400 font-bold text-white rounded mr-1 text-sm md:text-base leading-none flex items-center"
                                      style={{
                                        padding: "8px 6px",
                                      }}
                                      to={
                                        "/" +
                                        url_navigation +
                                        `/categoria/${convertSlug.data_slug(
                                          producto.Categoria.Descripcion
                                        )}`
                                      }
                                    >
                                      {producto.Categoria.Descripcion}
                                    </Link>
                                    <Link
                                      className="md:px-2 md:py-2.5 bg-gray-500 font-bold text-white rounded mr-1 text-sm md:text-base leading-none flex items-center"
                                      style={{
                                        padding: "8px 6px",
                                      }}
                                      to={
                                        "/" +
                                        url_navigation +
                                        `/categoria/${convertSlug.data_slug(
                                          producto.Categoria.Descripcion
                                        )}/${convertSlug.data_slug(
                                          producto.SubCategoria.Descripcion
                                        )}/`
                                      }
                                    >
                                      {producto.SubCategoria.Descripcion}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="product-detail-images w-full h-full grid md:grid-cols-2 ga-2 md:gap-4 pb-4">
                                <div className=" hidden md:flex md:gap-2 relative">
                                  <button
                                    className="absolute right-2 top-2 z-20 flex items-center justify-center bg-white w-12 h-12 rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
                                    onClick={() => show_ventana_favorito()}
                                  >
                                    <FontAwesomeIcon
                                      size="2x"
                                      icon={faHeart}
                                      className={classNames("", {
                                        "text-hiperorange":
                                          producto.EnListadoCliente,
                                        "hipergrisdark text-hipergrisdark ":
                                          !producto.EnListadoCliente,
                                      })}
                                    />
                                  </button>
                                  <div className="flex flex-col w-[15%] h-full">
                                    <button
                                      className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${producto.IdProducto} rounded-l-lg`}
                                      style={{
                                        display:
                                          imagenes?.length > 1
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faAngleUp}
                                        size="xl"
                                      />
                                    </button>
                                    <Swiper
                                      id={`carousel-${producto.IdProducto}-desktop-carrito-images`}
                                      direction="vertical"
                                      onSwiper={setThumbsSwiperCarritoDesktop}
                                      spaceBetween={5}
                                      slidesPerView={4}
                                      freeMode={true}
                                      watchSlidesProgress={true}
                                      modules={[FreeMode, Navigation, Thumbs]}
                                      className="mySwiper h-full"
                                    >
                                      {/*laterales web*/}

                                      {imagenes.map((foto, index) => (
                                        <SwiperSlide
                                          key={"ima" + index}
                                          className="w-full h-fit"
                                        >
                                          <div className="w-full h-fit relative">
                                            <Image
                                              src={
                                                foto.Url +
                                                (anchoPantalla >= 770
                                                  ? `&size=400x400`
                                                  : "&size=120x120")
                                              }
                                              alt={`${
                                                producto.Descripcion
                                              } Miniatura ${index + 1}`}
                                              placeholder={
                                                <div className="absolute animate-pulse inset-0 w-full h-12 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                              }
                                            />
                                          </div>
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                    <button
                                      className={`absolute z-10 top-0 bottom-0 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${producto.IdProducto} rounded-r-lg`}
                                      style={{
                                        display:
                                          imagenes?.length > 1
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faAngleDown}
                                        size="xl"
                                      />
                                    </button>
                                  </div>
                                  <div className="flex w-[85%]">
                                    <Swiper
                                      id={`carousel-${producto.IdProducto}-desktop-carrito-thumbs`}
                                      style={{
                                        "--swiper-navigation-color": "#000000",
                                        "--swiper-pagination-color": "#000000",
                                      }}
                                      spaceBetween={10}
                                      navigation={{
                                        nextEl: `.review-swiper-button-next-${producto.IdProducto}`,
                                        prevEl: `.review-swiper-button-prev-${producto.IdProducto}`,
                                      }}
                                      pagination={{
                                        type: "fraction",
                                      }}
                                      thumbs={{
                                        swiper:
                                          thumbsSwiperCarritoDesktop &&
                                          !thumbsSwiperCarritoDesktop.destroyed
                                            ? thumbsSwiperCarritoDesktop
                                            : null,
                                      }}
                                      modules={[Thumbs, Pagination]}
                                      className="mySwiper2"
                                    >
                                      {/*responsive*/}
                                      {imagenes.map((foto, index) => (
                                        <SwiperSlide
                                          key={"ima" + index}
                                          className="px-12"
                                        >
                                          <ImageZoom
                                            containerClassName="w-fit h-fit md:min-h-[295px] lg:min-h-[372px] xl:min-h-[448px] flex items-center justify-center"
                                            imageSrc={
                                              foto.Url + `&size=900x900`
                                            }
                                            highResImageSrc={
                                              foto.Url + `&size=900x900`
                                            }
                                            zoomFactor={2}
                                            alt={`${
                                              producto.Descripcion
                                            } Imagen ${index + 1}`}
                                            width={900}
                                            height={900}
                                            placeholder={
                                              <div className="absolute animate-pulse inset-0 w-full h-96 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                            }
                                          />
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                  </div>
                                </div>

                                <div className=" flex flex-col gap-1 md:hidden w-full items-center relative">
                                  {producto.ConOferta && (
                                    <div className="block md:hidden absolute top-6 left-1 z-10 -rotate-45">
                                      <div className="py-1 px-1.5 text-xs bg-hiperorange text-white inline-flex items-center gap-1 relative h-[22px] rounded-r-sm">
                                        <span className="font-semibold mr-1">
                                          -{descuento} %
                                        </span>
                                        <div className="absolute -right-[8px] rotate-45 h-4 w-4 flex items-center justify-center bg-hiperorange rounded-sm">
                                          <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <button
                                    className="absolute right-2 top-2 z-20 flex items-center justify-center bg-white w-12 h-12 rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
                                    onClick={() => show_ventana_favorito()}
                                  >
                                    <FontAwesomeIcon
                                      size="2x"
                                      icon={faHeart}
                                      className={classNames("", {
                                        "text-hiperorange":
                                          producto.EnListadoCliente,
                                        "hipergrisdark text-hipergrisdark ":
                                          !producto.EnListadoCliente,
                                      })}
                                    />
                                  </button>
                                  <div className="flex w-full relative h-[80%] gap-1 md:max-w-[22rem] mt-1 md:mt-4">
                                    <button
                                      className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${producto.IdProducto} rounded-l-lg`}
                                      style={{
                                        display:
                                          imagenes?.length > 1
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faAngleLeft}
                                        size="xl"
                                      />
                                    </button>
                                    <Swiper
                                      id={`carousel-${producto.IdProducto}-mobile-carrito-images`}
                                      style={{
                                        "--swiper-navigation-color": "#000000",
                                        "--swiper-pagination-color": "#000000",
                                      }}
                                      spaceBetween={10}
                                      navigation={{
                                        nextEl: `.review-swiper-button-next-${producto.IdProducto}`,
                                        prevEl: `.review-swiper-button-prev-${producto.IdProducto}`,
                                      }}
                                      // pagination={{
                                      //   type: "fraction",
                                      // }}
                                      thumbs={{
                                        swiper:
                                          thumbsSwiperCarritoMobile &&
                                          !thumbsSwiperCarritoMobile.destroyed
                                            ? thumbsSwiperCarritoMobile
                                            : null,
                                      }}
                                      modules={[
                                        Thumbs,
                                        // Pagination
                                      ]}
                                      className="mySwiper4 max-w-[calc(100vw-128px)]"
                                    >
                                      {/*responsive*/}
                                      {imagenes.map((foto, index) => (
                                        <SwiperSlide key={"ima" + index}>
                                          <Image
                                            src={foto.Url + `&size=400x400`}
                                            width={400}
                                            height={400}
                                            alt={`${
                                              producto.Descripcion
                                            } Imagen ${index + 1}`}
                                            placeholder={
                                              <div className="absolute animate-pulse inset-0 w-full h-60 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                            }
                                          />
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                    <button
                                      className={`absolute z-10 top-0 bottom-0 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${producto.IdProducto} rounded-r-lg`}
                                      style={{
                                        display:
                                          imagenes?.length > 1
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faAngleRight}
                                        size="xl"
                                      />
                                    </button>
                                  </div>
                                  <div className="flex w-full relative">
                                    <Swiper
                                      id={`carousel-${producto.IdProducto}-thumbs-carrito-images`}
                                      onSwiper={setThumbsSwiperCarritoMobile}
                                      spaceBetween={10}
                                      slidesPerView={6}
                                      freeMode={true}
                                      watchSlidesProgress={true}
                                      modules={[FreeMode, Navigation, Thumbs]}
                                      className="mySwiper3"
                                    >
                                      {/*laterales web*/}

                                      {imagenes.map((foto, index) => (
                                        <SwiperSlide key={"ima" + index}>
                                          <div className="w-fit h-fit relative">
                                            <div className="w-full h-fit relative">
                                              <Image
                                                src={foto.Url + `&size=400x400`}
                                                alt={`${
                                                  producto.Descripcion
                                                } Miniatura ${index + 1}`}
                                                placeholder={
                                                  <div className="absolute animate-pulse inset-0 w-full h-10 backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
                                                }
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                  </div>
                                </div>

                                <div className="z-10 md:min-h-[300px] lg:min-h-[400px] xl:min-h-[500px] relative">
                                  <ZoomedImage />
                                  <div className="style-categorias  ">
                                    <div className="flex justify-start">
                                      <div
                                        className="bg-gray-400   font-bold text-white rounded mr-1  flex align-items-center transition duration-300 ease-in-out hover:scale-105 "
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px 8px 10px 8px",
                                        }}
                                        onClick={() =>
                                          show_categoria(
                                            convertSlug.data_slug(
                                              producto.Categoria.Descripcion
                                            )
                                          )
                                        }
                                      >
                                        {producto.Categoria.Descripcion}
                                      </div>
                                      <div
                                        className="bg-gray-500  flex align-items-center font-bold  text-white rounded mx-1 transition duration-300 ease-in-out hover:scale-105"
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px 8px 10px 8px",
                                        }}
                                        onClick={() =>
                                          show_subcategoria(
                                            convertSlug.data_slug(
                                              producto.Categoria.Descripcion
                                            ),
                                            convertSlug.data_slug(
                                              producto.SubCategoria.Descripcion
                                            )
                                          )
                                        }
                                      >
                                        {producto.SubCategoria.Descripcion}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <div className="font-bold text-lg leading-none md:leading-7 md:text-[24px] md:my-1.5 md:h-auto">
                                      {producto.Descripcion}
                                    </div>
                                    {producto.ConOferta && (
                                      <div className="hidden md:block">
                                        <div className="py-2 px-3 text-lg bg-hiperorange text-white rounded-[20px] inline-flex items-center gap-2">
                                          <FontAwesomeIcon
                                            icon={faTag}
                                            className="w-[1.5rem] h-[1.5rem]"
                                          />
                                          <span className="font-semibold">
                                            -{descuento}% Dcto.
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div className="w-full flex flex-col gap-1">
                                      {producto.ConOferta && cantidad > 0 && (
                                        <div className="self-end block md:hidden">
                                          <div className="py-1 px-2 text-sm bg-hiperorange text-white rounded-[20px] inline-flex items-center gap-2">
                                            Bs. {formatNumber(ahorrado)}&nbsp;
                                            Ahorrado
                                          </div>
                                        </div>
                                      )}
                                      <div className="grid grid-cols-2 md:grid-cols-1 gap-1">
                                        <div className="flex flex-col">
                                          <div className="font-bold h-auto text-sm md:text-base">
                                            {_es_peso() ? (
                                              <span>Precio por Kg </span>
                                            ) : (
                                              <></>
                                            )}
                                            {_es_pieza() ? (
                                              <span>Precio por Kg </span>
                                            ) : (
                                              <></>
                                            )}
                                            {!_es_pieza() && !_es_peso() ? (
                                              <span>Precio por Und </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          <div className="text-base md:text-3xl font-bold leading-none">
                                            {producto.ConOferta ? (
                                              <div className="mb-0">
                                                {producto.Moneda}
                                                {". "}
                                                {formatNumber(
                                                  producto.PrecioOferta
                                                )}
                                                <del
                                                  className="text-zinc-400 px-2"
                                                  style={{
                                                    fontSize: "24px !important",
                                                  }}
                                                >
                                                  {producto.Moneda}
                                                  {". "}
                                                  {formatNumber(
                                                    producto.PrecioOriginal
                                                  )}
                                                </del>
                                              </div>
                                            ) : (
                                              <div className="mb-0 pl-0">
                                                {producto.Moneda}
                                                {". "}
                                                {formatNumber(
                                                  producto.PrecioVenta
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {cantidad > 0 && (
                                          <div className="flex flex-col text-right md:hidden">
                                            {/* <div>
                                          <p className="mb-0 font-bold">
                                            {_es_peso() ? "Peso" : ""}
                                            {_es_pieza() ? "Peso estimado" : ""}
                                          </p>
                                          {(_es_peso() || _es_pieza()) && (
                                            <p className="mb-0 font-bold">
                                              {formatNumber(estimado)} KG
                                            </p>
                                          )}
                                        </div> */}
                                            <div className="font-bold h-auto text-sm md:text-base">
                                              {!_es_pieza() && (
                                                <span>Total</span>
                                              )}
                                              {_es_pieza() && (
                                                <span>Total estimado</span>
                                              )}
                                            </div>
                                            <div className="text-base md:text-3xl font-bold leading-none">
                                              {(_es_peso() || _es_pieza()) && (
                                                <span className="mr-1 text-sm">
                                                  ({formatNumber(estimado)} KG)
                                                </span>
                                              )}
                                              {producto.Moneda}
                                              {". "}
                                              {formatNumber(total_estimado)}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        "controles-add-minus md:pb-2",
                                        {
                                          hidden:
                                            !_es_pieza() && cantidad === 0,
                                          "md:border-b-[1px] md:border-[#ABABAD]":
                                            cantidad > 0,
                                        }
                                      )}
                                    >
                                      {!producto.Fraccionado &&
                                        cantidad > 0 /* esnoremal*/ && (
                                          <div className="flex align-items-end controles_info_prod">
                                            <div className="flex w-full">
                                              <button
                                                className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                                onClick={() =>
                                                  reducir_carrito()
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  size="lg"
                                                  icon={
                                                    cantidad > 1
                                                      ? faMinus
                                                      : faTrashCan
                                                  }
                                                />
                                              </button>
                                              <div className="mx-1 flex justify-center flex-grow md:flex-grow-0 md:w-[100px]">
                                                <input
                                                  type="text"
                                                  className="w-full h-10 text-center outline-none bg-hipergris rounded-lg px-2 font-bold"
                                                  name="cantidad"
                                                  pattern="[0-9]{0,13}"
                                                  value={cantidad + " Und"}
                                                  onChange={(e) =>
                                                    inputchange(e)
                                                  }
                                                />
                                              </div>
                                              <button
                                                className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                                onClick={() => add_carrito()}
                                              >
                                                <FontAwesomeIcon
                                                  size="lg"
                                                  icon={faPlus}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      {_es_peso() && cantidad > 0 ? (
                                        <div className="flex align-items-end controles_info_prod">
                                          <div className="flex w-full">
                                            <button
                                              className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                              onClick={() =>
                                                actualizar_item(
                                                  0.1,
                                                  "decremento"
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                size="lg"
                                                icon={
                                                  cantidad > 0.1
                                                    ? faMinus
                                                    : faTrashCan
                                                }
                                              />
                                            </button>

                                            <button
                                              className="mx-1 flex justify-center align-items-center bg-white cursor-pointer flex-grow md:flex-grow-0 md:w-[100px]"
                                              onClick={() => abrirModal()}
                                            >
                                              <div className="w-full h-10 text-center outline-none bg-hipergris rounded-lg px-2 font-bold flex items-center justify-center">
                                                {cantidad_peso}
                                              </div>
                                            </button>
                                            <button
                                              className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                              onClick={() =>
                                                actualizar_item(
                                                  0.1,
                                                  "incremento"
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                size="lg"
                                                icon={faPlus}
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      {_es_pieza() /* espieza*/ ? (
                                        <div className="flex flex-col">
                                          <div className="text-sm md:text-base font-bold">
                                            Peso de unidades disponibles
                                          </div>
                                          {pesos.map((peso, index) => (
                                            <div
                                              className="flex gap-2 md:gap-4 lg:gap-8 my-1 md:mt-2 w-full bg-white md:mb-2 flex-wrap flex-row md:items-center"
                                              key={index}
                                            >
                                              <div className="bg-white h-10 rounded-l flex items-center flex-grow lg:flex-grow-0">
                                                <span className="font-bold">
                                                  Unidad {peso.PesoAproxMin} -{" "}
                                                  {peso.PesoAproxMax} kg
                                                </span>
                                              </div>
                                              <div className="flex w-full max-w-[196px]">
                                                <div className="flex gap-2 items-center">
                                                  {peso.Piezas >= 1 && (
                                                    <button
                                                      className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                                      onClick={() =>
                                                        actualizarDato(
                                                          peso,
                                                          "decremento"
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        size="lg"
                                                        icon={
                                                          peso.Piezas > 1
                                                            ? faMinus
                                                            : faTrashCan
                                                        }
                                                      />
                                                    </button>
                                                  )}

                                                  {peso.Piezas > 0 && (
                                                    <input
                                                      type="text"
                                                      className="  h-10 text-center outline-none bg-hipergris rounded-lg px-3 py-1 font-bold "
                                                      name="cantidad"
                                                      pattern="[0-9]{0,13}"
                                                      value={
                                                        peso.Piezas + " Und"
                                                      }
                                                      onChange={(e) =>
                                                        inputchangePeso(e, peso)
                                                      }
                                                      style={{
                                                        width: "auto",
                                                        maxWidth: "100px",
                                                      }}
                                                    />
                                                  )}

                                                  {peso.Piezas > 0 && (
                                                    <button
                                                      className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                                                      onClick={() =>
                                                        actualizarDato(
                                                          peso,
                                                          "incremento"
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faPlus}
                                                      />
                                                    </button>
                                                  )}
                                                </div>

                                                {peso.Piezas === 0 && (
                                                  <div className="w-full ">
                                                    <ButtonText
                                                      type="submit"
                                                      label="Agregar"
                                                      principal={true}
                                                      className="bg-hiperorange  text-white mb-0 hover:bg-hiperorange_hover  w-full"
                                                      onClick={() => {
                                                        actualizarDato(
                                                          peso,
                                                          "incremento"
                                                        );

                                                        pixel.trackEvent(
                                                          "AddToCart",
                                                          {
                                                            content_name:
                                                              producto.Descripcion, // Nombre del producto
                                                            content_ids: [
                                                              producto.IdProducto,
                                                            ], // ID del producto
                                                            content_type:
                                                              "product", // Tipo de contenido
                                                            value:
                                                              (producto.ConOferta
                                                                ? producto.PrecioOferta
                                                                : producto.PrecioVenta) *
                                                              peso.PesoAproxMax, // Valor del producto
                                                            currency: "BOB", // Moneda
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {(cantidad == 0 || cantidad_peso == "0g") &&
                                      _es_pieza() === false && (
                                        <div className="flex align-items-end controles_info_prod">
                                          <ButtonText
                                            type="submit"
                                            label="Agregar"
                                            principal={true}
                                            className="bg-hiperorange  text-white hover:bg-hiperorange_hover w-full md:w-auto min-w-32"
                                            onClick={() => show_botones()}
                                          />
                                        </div>
                                      )}

                                    {cantidad > 0 && (
                                      <div className="block-total hidden md:block">
                                        <div>
                                          <p className="mb-0 font-bold">
                                            {_es_peso() ? "Peso" : ""}
                                            {_es_pieza() ? "Peso estimado" : ""}
                                          </p>
                                          {(_es_peso() || _es_pieza()) && (
                                            <p className="mb-0 font-bold">
                                              {formatNumber(estimado)} KG
                                            </p>
                                          )}
                                        </div>

                                        {!_es_pieza() && (
                                          <div>
                                            <span className="style-letra-info-prod  ">
                                              {" "}
                                              Total{" "}
                                            </span>
                                          </div>
                                        )}
                                        {_es_pieza() && (
                                          <div>
                                            <span className="style-letra-info-prod  ">
                                              {" "}
                                              Total estimado{" "}
                                            </span>
                                          </div>
                                        )}
                                        <div className="listado flex    justify-start  ">
                                          <div className="style-letra-info-prod  style-texto-total  ">
                                            {producto.Moneda}.{" "}
                                            {formatNumber(total_estimado)}
                                          </div>

                                          {producto.ConOferta && (
                                            <div className="bg-hiperorange text-white    texto_ahorrado_info_prod  d-inline-block py-2    font-bold">
                                              Bs. {ahorrado}&nbsp; Ahorrado
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    <div className="text-hiperorange hover:text-hiperorange_hover ">
                                      <span
                                        className="cursor-pointer font-bold "
                                        onClick={() => show_ventana_favorito()}
                                      >
                                        {" "}
                                        Agregar a tus listas de compras{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center justify-center h-[50vh] flex-col gap-5">
                              <FontAwesomeIcon
                                className="text-gray-500"
                                icon={faCartCircleXmark}
                                size="6x"
                              />
                              <div className="flex flex-col gap-2">
                                <div className="text-gray-500 text-lg md:text-xl text-center">
                                  Producto no disponible en la sucursal
                                  seleccionada
                                </div>
                                <MensajeTelefonoProductoDisponible />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                isOpen={modalIsOpen}
                appElement={document.getElementById("root")}
                onRequestClose={closeModal}
                style={customStyles2}
                contentLabel="Example Modal"
                key={"keyModal"}
              >
                <FullScreenImage
                  imagenes={imagenes}
                  onClose={closeFullScreen}
                  tiene_varias_imagenes={"si"}
                  imagen={null}
                ></FullScreenImage>
              </Modal>
              <Modal
                isOpen={modal}
                ariaHideApp={false}
                onRequestClose={endModal}
                className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
                contentLabel="Seleccionar peso"
              >
                <div className="w-full flex flex-col h-full md:max-h-[600px]">
                  <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
                    <div className="flex flex-col h-full">
                      <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                        <h2 className="p-0 m-0 title_carrito_responsivo">
                          Seleccione el Peso
                        </h2>
                        <div className="absolute right-2 ">
                          <ButtonClose
                            className="text-white bg-transparent"
                            onClick={() => endModal()}
                          />
                        </div>
                      </div>
                      <div className="bg-white overflow-y-auto grid grid-cols-2 w-full h-full gap-2 p-4">
                        {pesajes.map((pesaje, index) => (
                          <button
                            key={`peso-${index}`}
                            className="w-full bg-[#EEEEEE] p-2 rounded hover:bg-gray-300 font-semibold text-xl flex items-center justify-center"
                            onClick={() => set_peso_pieza(pesaje)}
                          >
                            {pesaje.name}
                          </button>
                        ))}
                      </div>
                      <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center sticky bottom-0">
                        <div className="flex border-[3px] border-hiperorange p-1 rounded">
                          <button
                            disabled={cantidad <= 0}
                            className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center disabled:cursor-not-allowed"
                            onClick={() => actualizar_item(0.1, "decremento")}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <div className="mx-2 flex items-center justify-center min-w-10">
                            <span>{cantidad_peso}</span>
                          </div>
                          <button
                            className="w-10 h-10 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                            onClick={() => actualizar_item(0.1, "incremento")}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                isOpen={modal_favorito}
                ariaHideApp={false}
                onRequestClose={closeModalFavorito}
                className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
                contentLabel="favorito"
              >
                <Favorito
                  idProducto={producto.IdProducto}
                  IdSucursal={sucursal.IdSucursal}
                  cantidad={cantidad}
                  pesos={pesos}
                  onBlur={fueraFoco}
                  delete_producto={false}
                  ps_closeModalFavorito={closeModalFavorito}
                ></Favorito>
              </Modal>
              <Modal
                isOpen={openModalSucursal}
                appElement={document.getElementById("root")}
                onRequestClose={handleCloseModalSucursal}
                className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:w-auto md:max-w-3xl mx-auto focus-visible:outline-none"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
                contentLabel="Example Modal"
                key={"sdfsdg"}
              >
                <Sucursal onCloseModal={handleCloseModalSucursal} />
              </Modal>
            </>
          )}
        </Layout>
      </ZoomProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  producto: state.Producto.producto,
  sucursal: state.BranchOffice.sucursal,
  sucursales: state.BranchOffice.sucursales,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  list_carrito: state.Carrito.list_carrito,
  profile: state.Profile.profile,
  isLoading: state.Producto.isFetching,
});

export default connect(mapStateToProps, {
  store_carrito,
  limpiar_item_producto,
  get_producto_item,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  actualizar_item_carrito,
  set_all_prod_category,
  store_carrito_data,
  get_carrito_por_sucursal,
  limpiar_solo_carrito,
  setSwNoAlert,
  set_cambio_detalle_carrito,
  update_list_carrito_compras,
})(InformacionProducto);
