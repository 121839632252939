import { EventData, TrackableEventNameEnum } from "react-use-facebook-pixel";

function getTotal(pedidos: any[]) {
  return pedidos.reduce((total, pedido) => {
    // Extraer el valor numérico de CostoEnvio eliminando todo excepto los dígitos y el punto decimal
    const costoEnvio = parseFloat(pedido.CostoEnvio?.replace("Bs. ", ""));
    // Sumar ImporteSubtotal y el valor numérico de CostoEnvio para este pedido
    const totalPedido =
      pedido.ImporteSubtotal + (isNaN(costoEnvio) ? 0 : costoEnvio);
    // Acumular el total
    return total + totalPedido;
  }, 0);
}

function getProductIds(pedidos: any[]) {
  const idsProductos = pedidos.flatMap((pedido: any) =>
    pedido.Productos.map((producto: any) => producto.IdProducto)
  );

  // Usamos Set para eliminar duplicados y luego convertimos de nuevo a array
  return [...new Set(idsProductos)];
}

function contarCantidadProductos(pedidos: any[]) {
  return pedidos.reduce((total, pedido) => {
    return (
      total +
      pedido.Productos.reduce((subtotal: any, producto: any) => {
        let cantidad = producto.Cantidad;
        if (producto.Pesos) {
          cantidad = producto.Pesos.reduce((total: any, producto: any) => {
            const cantidadProducto = producto.Piezas * producto.PesoAproxMax;
            return total + cantidadProducto;
          }, 0);
        }
        return subtotal + cantidad;
      }, 0)
    );
  }, 0);
}

function getProducts(pedidos: any[]) {
  return pedidos.flatMap((pedido: any) =>
    pedido.Productos.map((producto: any) => {
      let cantidad = producto.Cantidad;
      if (producto.Pesos) {
        cantidad = producto.Pesos.reduce((total: any, producto: any) => {
          const cantidadProducto = producto.Piezas * producto.PesoAproxMax;
          return total + cantidadProducto;
        }, 0);
      }
      return {
        id: producto.IdProducto,
        quantity: cantidad,
        branch: pedido.Sala.Descripcion,
      };
    })
  );
}

export function useBuildTrackingPurchaseData() {
  const buildTrackingPurchaseData = (pedidos: any[]) => {
    const data: EventData[TrackableEventNameEnum.Purchase] = {
      currency: "BOB",
      value: getTotal(pedidos),
      content_type: "product",
      content_ids: getProductIds(pedidos),
      num_items: contarCantidadProductos(pedidos),
      contents: getProducts(pedidos),
    };

    return data;
  };

  return { buildTrackingPurchaseData };
}
