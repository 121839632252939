import { faCartShopping, faHeart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { connect } from "react-redux";
import { actualizar_lista_compra_favorito } from "../../src/redux/actions/shoppingList";
import { set_cambio_favorito } from "../redux/actions/carrito";
import { colorEnUmbralDeBlanco } from "../components/Utils/utils-colores";
import classNames from "classnames";
import useFacebookPixel from "../modules/shared/ui/hooks/facebook-pixel/use-facebook-pixel";

const ItemFavorito = ({
  ps_shopping_list,
  recargar_listado,
  idProducto,
  IdSucursal,
  cantidad,
  pesos,
  peso_definitivo,
  set_cambio_favorito,
  favorito,
  actualizar_lista_compra_favorito,
  peticion,
}) => {
  const pixel = useFacebookPixel();
  const [selected, setSelected] = useState(0);
  const colorEsEnUmbralDeBlanco = colorEnUmbralDeBlanco(
    ps_shopping_list.Color,
    180
  );

  const marcar_favorito = (item, e) => {
    setSelected(item.Id);
    ejecutar_procesos(item);
  };

  const ejecutar_procesos = async (item) => {
    var prod = {
      IdProducto: idProducto,
      IdSucursal: IdSucursal,
      Cantidad: cantidad,
      PesoDefinido: peso_definitivo,
      Pesos: pesos ? pesos : [],
    };
    const datos = {
      Id: item.Id,
      Descripcion: item.Descripcion,
      Color: item.Color,
      Productos: [],
      EliminarProductos: [],
    };

    if (esFavorito()) {
      datos.EliminarProductos.push(prod);
    } else {
      datos.Productos.push(prod);
    }
    await actualizar_lista_compra_favorito(
      datos,
      datos.Productos,
      datos.EliminarProductos,
      IdSucursal
    );
    if(!esFavorito()) {
      pixel.trackEvent("AddToWishlist", {
        currency: "BOB",
        content_ids: [idProducto],
        contents: [
          {
            id:idProducto,
            quantity: cantidad,
            wishList: item.Descripcion
          }
        ]
      })
    }
    
    recargar_listado(idProducto, IdSucursal, item.Id);
    set_cambio_favorito(true);
    setSelected(0);
  };

  const esFavorito = () => {
    return typeof favorito !== "undefined" && favorito === true;
  };

  return (
    <>
      {ps_shopping_list && (
        <div
          className={classNames(
            "flex justify-between px-3 py-1 mb-1 rounded-lg transition-colors duration-300 font-medium cursor-pointer border-2",
            {
              "text-white": !colorEsEnUmbralDeBlanco,
              "text-black": colorEsEnUmbralDeBlanco,
            }
          )}
          style={{
            background: ps_shopping_list.Color,
            borderColor: colorEsEnUmbralDeBlanco
              ? "#000000"
              : ps_shopping_list.Color,
          }}
          onClick={(e) => marcar_favorito(ps_shopping_list, e)}
        >
          <div
            className="flex cursor-pointer gap-2 items-center w-full"
            key={ps_shopping_list.Id}
          >
            <FontAwesomeIcon icon={faCartShopping} />
            <span className="flex-grow truncate"> {ps_shopping_list.Descripcion} </span>
            <div className="w-[18px]">
              {peticion && selected === ps_shopping_list.Id ? (
                <ColorRing
                  colors={
                    colorEsEnUmbralDeBlanco
                      ? ["#000000", "#000000", "#000000", "#000000", "#000000"]
                      : ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
                  }
                  width={18}
                  height={18}
                  ariaLabel="circles-loading"
                  visible={true}
                  className="items-center"
                />
              ) : (
                esFavorito() &&
                selected !== ps_shopping_list.Id && (
                  <FontAwesomeIcon icon={faHeart} className="cursor-pointer " />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  peticion: state.Peticion.peticion,
});

export default connect(mapStateToProps, {
  set_cambio_favorito,
  actualizar_lista_compra_favorito,
})(ItemFavorito);
