import React from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Image } from "../image";
import "../../assets/styles/hiper.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import SeccionInicio from "../../widgets/seccion_inicio";
const breakpoints = {
  300: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  400: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 1,
    slidesPerGroup: 2,
    spaceBetween: 15, //10
  },
  768: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 15, //10
  },

  992: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 15, //10
  },
  1200: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 15, //10
  },
  1300: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 15, //30
  },

  1400: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 15, //30
  },
};

const Capsula = ({ descripcion, idSeccion, Items: productos }) => {
  
  const shouldLoop =
    productos.length >
    Math.max(...Object.values(breakpoints).map((bp) => bp.slidesPerView));
  return (
    <>
      {productos?.length > 0 && (
        <div>
          <div className="h-auto w-[100%] flex gap-1 relative">
            <button
              className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${idSeccion} rounded-l-lg`}
            >
              <FontAwesomeIcon icon={faAngleLeft} size="xl" />
            </button>
            <Swiper
              modules={[Navigation]}
              breakpoints={breakpoints}
              slidesPerView={1}
              loop={shouldLoop}
              loopFillGroupWithBlank={shouldLoop}
              navigation={{
                nextEl: `.review-swiper-button-next-${idSeccion}`,
                prevEl: `.review-swiper-button-prev-${idSeccion}`,
              }}
              className="mySwiper w-full"
            >
              {productos.map((producto, index) => (
                <SwiperSlide
                  key={index}
                  className="flex justify-center rounded-lg overflow-hidden"
                >
                  <SeccionInicio
                    texto1={producto.Titulo}
                    texto2={producto.Descripcion}
                    imagen={producto.UrlFoto}
                    url={producto.Url_Destino}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              className={`absolute z-10 top-0 bottom-0 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${idSeccion} rounded-r-lg`}
            >
              <FontAwesomeIcon icon={faAngleRight} size="xl" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Capsula);
