import React, { useState } from "react";
import Layout from "../hocs/Layout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  update_usuario,
  eliminar_usuario,
  get_user_data,
  update_user_data,
} from "../../src/redux/actions/profile";
import { info_session } from "../../src/redux/actions/profile";
import Alert from "../components/Swalert";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ButtonText from "../widgets/button_text";
import { cleadSwAlert } from "../redux/actions/swalert";
import "../assets/styles/responsive.scss";
import "../assets/styles/globalColorPrincipal.css";
import "../assets/styles/stylePageGlobals.scss";
import Page_message from "../components/Page_message";
import { format, set } from "date-fns";
import { Helmet } from "react-helmet-async";
import PhoneInputWithCountrySelector from "react-phone-number-input";
import { useIsDefaultStore } from "../hooks/useIsDefaultStore";

const DATE_FORMAT = "yyyy-MM-dd";

const Cuenta = ({
  show_alert,
  redirect,
  cleadSwAlert,
  info_session,
  profile,
  update_usuario,
  eliminar_usuario,
  sucursal,
  get_user_data,
  update_user_data,
}) => {
  const { isDefaultStore } = useIsDefaultStore();

  const [tipo, setTipo] = useState("");
  const [nombres, setNombres] = useState("");
  const [ApellidoPaterno, setApellidoPaterno] = useState("");
  const [ApellidoMaterno, setApellidoMaterno] = useState("");
  const [FechaNacimiento, setFechaNacimiento] = useState(null);
  const [NroDocumento, setNroDocumento] = useState("");
  const [Email, setEmail] = useState("");
  const [NroTelefono, setNroTelefono] = useState("");
  const [NroTelefonoClean, setNroTelefonoClean] = useState("");
  const [Prefijo, setPrefijo] = useState("");
  const [genero, setGenero] = useState("");
  useEffect(() => {
    if (show_alert && redirect !== null) {
      cleadSwAlert();
      if (redirect.type === "back") {
        window.history.back();
      }
    }
  }, [show_alert]);

  useEffect(() => {
    //
    return () => {
      setNombres("");
      setApellidoPaterno("");
      setFechaNacimiento("");
    };
  }, []);

  // useEffect(() => {
  //   if (!profile) {
  //     cargar_datos_sesion();
  //     // info_session();
  //   } else {
  //     if (!profile.Telefono) {
  //       // navigate("/registro-mobil");
  //       return;
  //     }
  //     var formatedBirthday = profile.FechaNacimiento?.split("/")
  //       .reverse()
  //       .join("/");
  //     setNombres(profile.Nombres);
  //     setApellidoPaterno(profile.ApellidoPaterno);
  //     setApellidoMaterno(profile.ApellidoMaterno);
  //     setFechaNacimiento(
  //       formatedBirthday
  //         ? format(new Date(formatedBirthday), DATE_FORMAT)
  //         : null
  //     );
  //     setNroDocumento(profile.NroDocumento);
  //     setEmail(profile.Email);
  //     setPrefijo(profile.Prefijo);
  //     setNroTelefonoClean(profile.Telefono);
  //     setNroTelefono(profile.Prefijo + profile.Telefono);
  //     setGenero(profile.Genero?.Id);
  //   }
  // }, [profile]);

  const cargar_datos_sesion = async () => {
    await info_session();
  };
  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const getUser = async (codigoCliente) => {
    let user = await get_user_data(codigoCliente);
    //
    if (!profile) {
      return;
    }
    if (!user) {
      return;
    }
    //
    setFechaNacimiento(formatDate(user.fechaNacimiento) || "");
    setNombres(user.nombres);
    setApellidoPaterno(user.apellidoPaterno);
    setApellidoMaterno(user.apellidoMaterno || "");
    setEmail(user.mail);
    setGenero(user.sexo === 0 ? "" : user.sexo);
    setNroTelefono(`${user.prefijo}${user.telefono}`);
  };
  useEffect(() => {
    getUser(profile.CodigoCliente);
  }, [profile]);
  const onSubmit = (e) => {
    e.preventDefault();

    const datosClientes = {
      nombre: nombres.trim(),
      apellidoPaterno: ApellidoPaterno.trim(),
      apellidoMaterno: ApellidoMaterno ? ApellidoMaterno.trim() : "",
      fechaNacimiento: FechaNacimiento ? FechaNacimiento : "",
      correoElectronico: Email ? Email.trim() : "",
      sexo: genero ? genero : "",
    };

    const datos = {
      Nombres: nombres,
      ApellidoPaterno: ApellidoPaterno,
      ApellidoMaterno: ApellidoMaterno,
      Telefono: NroTelefonoClean,
      Email: Email,
      Genero: genero,
      FechaNacimiento: FechaNacimiento,
      // NroDocumento: NroDocumento,
    };

    // let object = {
    //   type: "back",
    // };
    if (tipo === "eliminar") {
      delete_registro();
    } else {
      update_user_data(datosClientes);
      // update_usuario(datos, object);
    }
  };
  async function delete_registro(id) {
    let resp = await Page_message.delete_register_confirmation(
      "¿Estas seguro de eliminar tu cuenta?"
    );
    if (resp) {
      eliminar_usuario();
    }
  }

  return (
    <>
      <Helmet>
        <title>{`Mi cuenta | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="content_info py-4">
          <div className="container">
            <h1 className="text-2xl md:text-3xl text-center text-hiperorange mb-4">
              Mi cuenta
            </h1>
            <div className="flex flex-column mt-1 items-center">
              <div className="md:w-[40rem] contenedor_mobile h-auto">
                <div className="w-full ">
                  <form
                    onSubmit={(e) => onSubmit(e)}
                    className="bg-white shadow rounded-xl px-8 pt-6 pb-8 mb-4"
                  >
                    <div className="mb-3">
                      <label
                        // className="peer-required:after:content-['*'] order-1 block fontzize_label text-black mb-2"
                        className="block fontzize_label text-black mb-2"
                        htmlFor="name"
                      >
                        Nombre(s) *
                      </label>
                      <input
                        id="name"
                        type="text"
                        value={nombres}
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        onChange={(e) => {
                          const newValue = e.target.value.replace(
                            /[^a-zA-ZáéíóúñÁÉÍÓÚÑ\s\d]/g,
                            ""
                          );
                          setNombres(newValue);
                        }}
                        required
                        minLength={3}
                      />
                    </div>
                    <div className="grid md:grid-cols-2 gap-3 mb-3">
                      <div>
                        <label
                          className="block fontzize_label text-black mb-2"
                          htmlFor="apellido-paterno"
                        >
                          Apellido paterno*
                        </label>
                        <input
                          id="apellido-paterno"
                          type="text"
                          value={ApellidoPaterno}
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          required
                          minLength={3}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              /[^a-zA-ZáéíóúñÁÉÍÓÚÑ\s\d]/g,
                              ""
                            );
                            setApellidoPaterno(newValue);
                          }}
                        />
                      </div>
                      <div>
                        <label
                          className="block fontzize_label text-black mb-2"
                          htmlFor="apellido-materno"
                        >
                          Apellido materno
                        </label>
                        <input
                          id="apellido-materno"
                          type="text"
                          defaultValue={ApellidoMaterno}
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          onChange={(e) => setApellidoMaterno(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-3 mb-3">
                      <div>
                        <label
                          className="block fontzize_label text-black mb-2"
                          htmlFor="date"
                        >
                          Fecha de nacimiento
                        </label>
                        <input
                          id="date"
                          type="date"
                          max={format(Date.now(), "yyyy-MM-dd")}
                          defaultValue={FechaNacimiento}
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          // required
                          onChange={(e) => setFechaNacimiento(e.target.value)}
                        />
                      </div>
                      <div>
                        <label
                          className="block fontzize_label text-black mb-2"
                          htmlFor="genero"
                        >
                          Género*
                        </label>
                        <select
                          id="genero"
                          name="genero"
                          value={genero}
                          onChange={(e) => setGenero(e.target.value)}
                          required
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value="9">Femenino</option>
                          <option value="8">Masculino</option>
                        </select>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="block fontzize_label text-black mb-2"
                        htmlFor="email"
                      >
                        Correo
                      </label>
                      <input
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="email"
                        type="email"
                        value={Email}
                        // required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="block fontzize_label text-black mb-2"
                        htmlFor="phone"
                      >
                        Número de teléfono
                      </label>
                      <div
                        className={` pointer-events-none block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500 disabled:bg-gray-100 disabled:cursor-not-allowed ${"disabled"}`}
                      >
                        <PhoneInputWithCountrySelector
                          value={"+" + NroTelefono}
                          onChange={(e) => {}}
                        />
                      </div>
                    </div>

                    {/* <div className="mb-3">
                    <label
                      className="block fontzize_label text-black mb-2"
                      htmlFor="ci"
                    >
                      Documento de identidad:
                    </label>
                    <input
                      className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                      id="ci"
                      type="text"
                      value={NroDocumento}
                      required
                      minLength={5}
                      maxLength={15}
                      pattern="[0-9]+"
                      onChange={(e) => setNroDocumento(e.target.value)}
                    />
                  </div> */}
                    <div className="grid grid-rows-2 gap-3 text-white">
                      <ButtonText
                        type="submit"
                        label="Actualizar información"
                        className="bg-hiperorange hover:bg-hiperorange_hover"
                        principal={true}
                        onClick={() => setTipo("actualizar")}
                      />
                      <ButtonText
                        type="submit"
                        label="Eliminar cuenta"
                        className="bg-red-600 hover:bg-red-700 "
                        principal={false}
                        onClick={() => setTipo("eliminar")}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
  show_alert: state.Swalerts.show_alert,
  redirect: state.Swalerts.redirect,
  sucursal: state.BranchOffice.sucursal,
});

export default connect(mapStateToProps, {
  info_session,
  update_usuario,
  cleadSwAlert,
  eliminar_usuario,
  get_user_data,
  update_user_data,
})(Cuenta);
