import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Favorito from "./favoritoMycart";
import {
  actualizar_item_carrito,
  add_al_carrito,
  dec_al_carrito,
  inc_al_carrito,
  remove_al_carrito,
} from "../redux/actions/carrito";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faHeart,
  faTrash,
  faTrashCan,
  faTag,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ModalPeso from "../components/modal/modal_peso";
import convertSlug from "../components/Utils/slug";

import "../assets/styles/globalColorPrincipal.css";
import "../assets/styles/responsive.scss";
import ButtonText from "./button_text";
import Modal from "react-modal";
import RegistroInit from "../components/modal/registroUser/registro_init";
import Sucursal from "../components/modal/sucursal";
import { Image } from "../components/image";
import classNames from "classnames";
import { useThousandSeparator } from "../hooks/useThousandSeparator";
import useFacebookPixel from "../modules/shared/ui/hooks/facebook-pixel/use-facebook-pixel";

const customStylesModalSucursal = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    overflow: "hidden",
  },
};

const style_favorito = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    border: "none",
    background: "transparent",
  },
};

function ItemProducto({
  actualizar_item_carrito,
  producto,
  producto_db,
  carrito,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  remove_al_carrito,
  totalQuantities,
  ps_IdSucursal,
  profile,
  direccion,
  sucursales,
  sucursal,
  key,
}) {
  const pixel = useFacebookPixel();
  const { numberFormatter } = useThousandSeparator();
  const navigate = useNavigate();
  const { ciudadp, sucursalp } = useParams();
  const [cantidad, setCantidad] = useState(0);
  const [pesos, guardarPesos] = useState([]);
  const [estado_modal, setEstadoModal] = useState(false);
  const [modal_favorito, setModalFavorito] = useState(false);
  const [enListadoCliente, setEnListadoCliente] = useState(null);

  const [modal_peso, setModalPeso] = useState(false);
  const openModalPeso = () => setModalPeso(true);
  const closeModalPeso = () => setModalPeso(false);

  const [openModalUserRegister, setOpenModalUserRegister] = useState(false);
  const ModalUserRegister = () => setOpenModalUserRegister(true);
  const [openModalSucursal, setOpenModalSucursal] = useState(false);

  const tieneCobertura = useMemo(() => {
    if (!direccion) return true;
    const sucursalEncontrada = sucursales?.find(
      (suc) => suc.IdMarket === sucursal.IdMarket
    );

    const locatarioEncontrado = sucursalEncontrada?.Locatarios.find(
      (locatario) => locatario.IdSucursal === sucursal.IdSucursal
    );
    return !!locatarioEncontrado;
  }, [sucursal, sucursales, direccion]);

  const isUserAuthenticated =
    profile?.Telefono != null && profile?.Telefono !== "";
  const handleOpenModalSucursal = () => {
    setOpenModalSucursal(true);
  };

  const handleCloseModalSucursal = () => {
    setOpenModalSucursal(false);
  };
  const _es_pieza = () => {
    if (producto) {
      return producto.Fraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };
  const openModalFavorito = () => {
    if (_es_pieza()) {
      let producto_carrito = carrito.find(
        (prd) => prd.IdProducto === producto.IdProducto
      );
      if (producto_carrito) {
        guardarPesos(producto_carrito.Pesos);
      }
    }
    setModalFavorito(true);
  };
  const closeModalFavorito = () => {
    guardarPesos([]);
    setModalFavorito(false);
  };

  useEffect(() => {
    if (producto_db) {
      if (producto_db.IdProducto === producto.IdProducto) {
        setEnListadoCliente(producto_db.EnListadoCliente);
      }
    }
  }, [producto_db]);
  useEffect(() => {
    carrito.forEach((item) => {
      if (item.IdProducto === producto.IdProducto) {
        setCantidad(item.cantidad);
      }
    });
  }, []);

  useEffect(() => {
    let findPro = carrito.find(
      (item) => item.IdProducto === producto.IdProducto
    );
    typeof findPro == "object" ? setCantidad(findPro.Cantidad) : setCantidad(0);
  }, [totalQuantities]);
  useEffect(() => {
    let findPro = carrito.find(
      (item) => item.IdProducto === producto.IdProducto
    );
    typeof findPro == "object" ? setCantidad(findPro.Cantidad) : setCantidad(0);
  }, [carrito]);

  const decrement_carrito = () => {
    if (!isUserAuthenticated) {
      ModalUserRegister();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    if (_es_pieza()) {
      navigate(
        `/${convertSlug.data_barra(ciudadp + " " + sucursalp)}/producto/${
          producto.IdProducto
        }/${convertSlug.data_slug(producto.Descripcion)}`
      );
    } else if (_es_peso()) {
      set_peso("decremento");
    } else {
      normal("decremento");
    }
  };

  const incremento_carrito = () => {
    if (!isUserAuthenticated) {
      ModalUserRegister();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    if (_es_pieza()) {
      navigate(
        `/${convertSlug.data_barra(ciudadp + " " + sucursalp)}/producto/${
          producto.IdProducto
        }/${convertSlug.data_slug(producto.Descripcion)}`
      );
    } else if (_es_peso()) {
      set_peso("incremento");
    } else {
      normal("incremento");
    }
  };

  const normal = (operacion) => {
    if (operacion === "incremento") {
      inc_al_carrito(producto);
    } else if (cantidad === 1) {
      remove_al_carrito(producto);
    } else {
      dec_al_carrito(producto);
    }
  };

  const set_peso = (operacion) => {
    let resultado = 0;
    let valor = 0.1;
    resultado =
      operacion === "incremento"
        ? Number((cantidad + valor).toFixed(1))
        : Number((cantidad - valor).toFixed(1));
    if (operacion === "incremento" && cantidad === 0) {
      setCantidad(resultado);
      add_al_carrito(producto, resultado);
    } else if (operacion === "decremento" && cantidad === valor) {
      setCantidad(resultado);
      remove_al_carrito(producto);
    } else {
      setCantidad(resultado);
      actualizar_item_carrito(producto, resultado);
    }
  };

  const anadir_al_carrito = () => {
    if (!isUserAuthenticated) {
      ModalUserRegister();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    let cantidad;
    if (_es_pieza()) {
      navigate(
        `/${convertSlug.data_barra(ciudadp + " " + sucursalp)}/producto/${
          producto.IdProducto
        }/${convertSlug.data_slug(producto.Descripcion)}`
      );
      return;
    } else if (_es_peso()) {
      cantidad = 0.1;
      add_al_carrito(producto, 0.1);
    } else {
      cantidad = 1;
      add_al_carrito(producto, 1);
    }
    if (cantidad) {
      pixel.trackEvent("AddToCart", {
        content_name: producto.Descripcion, // Nombre del producto
        content_ids: [producto.IdProducto], // ID del producto
        content_type: "product", // Tipo de contenido
        value: producto.ConOferta
          ? producto.PrecioOferta
          : producto.PrecioVenta * cantidad, // Valor del producto
        currency: "BOB", // Moneda
      });
    }
  };

  const _es_peso = () => {
    if (producto) {
      return producto.Fraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const verify_es_peso = () => {
    if (producto) {
      return producto.Fraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const redondeo = (num, dec) => {
    return Number(num.toFixed(dec));
  };

  const abrirModalPeso = (producto) => {
    if (!isUserAuthenticated) {
      ModalUserRegister();
      return;
    }
    if (!tieneCobertura) {
      handleOpenModalSucursal();
      return;
    }
    setEstadoModal(true);
    openModalPeso();
  };

  const operacion_peso = (operacion) => {
    let resultado = 0;
    let valor = 0.1;
    let procesoResult =
      operacion === "incremento" ? cantidad + valor : cantidad - valor;
    resultado = redondeo(procesoResult, 1);
    if (operacion === "incremento" && cantidad === 0) {
      setCantidad(resultado);
      add_al_carrito(producto, resultado);
    } else if (operacion === "decremento" && cantidad === valor) {
      setCantidad(resultado);
      remove_al_carrito(producto);
    } else {
      setCantidad(resultado);
      actualizar_item_carrito(producto, resultado);
    }
  };

  const set_peso_pieza = (peso) => {
    let procesoResult = peso.peso / 1000;
    let resultado = redondeo(procesoResult, 1);

    setCantidad(resultado);
    actualizar_item_carrito(producto, resultado);
  };

  const fueraFoco = () => {};

  const formatPrice = (value) => {
    return numberFormatter.format(value);
  };
  const cerrarVentana = (valor) => {
    setOpenModalUserRegister(false);
  };

  const productoEstaEnLista = useMemo(() => {
    if (enListadoCliente !== null) return enListadoCliente;
    return producto.EnListadoCliente;
  }, [enListadoCliente, producto]);

  const productUrl = `/${convertSlug.data_barra(
    ciudadp + " " + sucursalp
  )}/producto/${producto.IdProducto}/${convertSlug.data_slug(
    producto.Descripcion
  )}`;

  const displayTrashIcon = useMemo(() => {
    const esProductoPorPeso = verify_es_peso();

    if (esProductoPorPeso) {
      if (esProductoPorPeso && cantidad <= 0.1) return true;
    } else {
      if (cantidad <= 1) return true;
    }

    return false;
  }, [cantidad, producto]);
  const _descuento = () => {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();
  return (
    <>
      <article className="w-full h-full bg-white flex flex-col rounded-lg  relative overflow-hidden ">
        <div className="group">
          <div className="relative">
            <Link
              to={productUrl}
              className="relative flex justify-center transition duration-200 ease-in-out transform group-hover:scale-105 contenedor_image cursor-pointer mb-1 w-full"
            >
              {producto.ConOferta && (
                <div className="absolute top-6 left-1 z-10 -rotate-45">
                  <div className="py-1 px-1.5 text-xs bg-hiperorange text-white inline-flex items-center gap-1 relative h-[22px] rounded-r-sm">
                    <span className="font-semibold mr-1">-{descuento} %</span>
                    <div className="absolute -right-[8px] rotate-45 h-4 w-4 flex items-center justify-center bg-hiperorange rounded-sm">
                      <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full h-0 pb-[100%] relative rounded-lg">
                <Image
                  src={producto.UrlFoto + "&size=400x400"}
                  alt={producto.Descripcion}
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                />
              </div>
            </Link>
            <div className="absolute top-1 right-1 inline">
              <button
                className="bg-white w-8 h-8 flex items-center justify-center rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
                onClick={
                  isUserAuthenticated ? openModalFavorito : ModalUserRegister
                }
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faHeart}
                  className={classNames("", {
                    "text-hiperorange": productoEstaEnLista,
                    "hipergrisdark text-hipergrisdark ": !productoEstaEnLista,
                  })}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1" style={{ height: "100%" }}>
          <Link
            className="hover:underline h-full leading-tight text-gray-700"
            to={productUrl}
          >
            {producto.Descripcion}
          </Link>
          <div
            className="flex flex-col items-end justify-center gap-2"
            style={{ height: "100%" }}
          >
            {producto.ConOferta ? (
              <div className="flex gap-2 w-100">
                <span className="text-black font-bold ">
                  {producto.Moneda}. {formatPrice(producto.PrecioOferta)}{" "}
                </span>
                <del className="text-zinc-400">
                  {producto.Moneda}. {formatPrice(producto.PrecioOriginal)}
                </del>
              </div>
            ) : (
              <div className="flex gap-2 w-100">
                <span className="text-black font-bold">
                  {producto.Moneda}. {formatPrice(producto.PrecioVenta)}{" "}
                </span>
              </div>
            )}
            <div className="w-100 text-white min-h-7">
              {cantidad === 0 ? (
                <ButtonText
                  label="Agregar"
                  className="bg-hiperorange py-1 hover:bg-hiperorange_hover"
                  onClick={(event) => {
                    event.stopPropagation();
                    anadir_al_carrito();
                  }}
                />
              ) : (
                <div className="flex-auto text-white flex">
                  <div className="flex-auto  justify-center  gap-1 text-white flex   rounded-2xl">
                    <button
                      className="flex h-10 w-10 bg-hiperorange py-1 hover:bg-hiperorange_hover rounded text-white self-center justify-center items-center "
                      onClick={(event) => {
                        event.stopPropagation();
                        decrement_carrito();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={displayTrashIcon ? faTrashCan : faMinus}
                        className="text-white"
                      />
                    </button>

                    {verify_es_peso() ? (
                      <div className="text-red-600 flex-grow">
                        <button
                          className="flex h-10 p-2 w-full bg-hipergris rounded self-center justify-center items-center text-black font-bold"
                          onClick={(event) => {
                            event.stopPropagation();
                            abrirModalPeso(producto);
                          }}
                        >
                          {redondeo(cantidad, 2)} kg
                        </button>
                      </div>
                    ) : (
                      <div className="self-center  flex-grow">
                        <div className="flex h-10 p-2 bg-hipergris rounded self-center justify-center items-center text-black font-bold">
                          {cantidad} Und
                        </div>
                      </div>
                    )}

                    <button
                      className="flex h-10 w-10 py-1 bg-hiperorange hover:bg-hiperorange_hover rounded  text-white self-center justify-center items-center"
                      onClick={(event) => {
                        event.stopPropagation();
                        incremento_carrito();
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} className="text-white" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>

      {estado_modal && (
        <ModalPeso
          ps_modal_peso={modal_peso}
          ps_closeModalPeso={closeModalPeso}
          ps_objectProducto={0}
          ps_operacion_peso={operacion_peso}
          ps_cantidad={cantidad}
          ps_set_peso_pieza={set_peso_pieza}
        ></ModalPeso>
      )}
      <Modal
        isOpen={modal_favorito}
        ariaHideApp={false}
        onRequestClose={closeModalFavorito}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="favorito"
      >
        <Favorito
          idProducto={producto.IdProducto}
          IdSucursal={ps_IdSucursal}
          cantidad={cantidad}
          pesos={pesos}
          onBlur={fueraFoco}
          ps_closeModalFavorito={closeModalFavorito}
        ></Favorito>
      </Modal>

      {openModalUserRegister && (
        <RegistroInit setCloseModal={cerrarVentana}></RegistroInit>
      )}

      <Modal
        isOpen={openModalSucursal}
        appElement={document.getElementById("root")}
        onRequestClose={handleCloseModalSucursal}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 max-w-lg w-full md:w-auto md:max-w-3xl mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="Example Modal"
        key={"sdfsdg"}
      >
        <Sucursal onCloseModal={handleCloseModalSucursal} />
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  carrito: state.Carrito.carrito,
  totalQuantities: state.Carrito.totalQuantities,
  profile: state.Profile.profile,
  producto_db: state.Producto.producto,
  direccion: state.Location.direccion,
  sucursales: state.BranchOffice.sucursales,
  sucursal: state.BranchOffice.sucursal,
});

export default connect(mapStateToProps, {
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  remove_al_carrito,
  actualizar_item_carrito,
})(ItemProducto);
