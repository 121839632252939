import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useBuildFooterAppLinks } from "../../modules/shared/ui/hooks/use-build-footer-app-links";
import { useBuildFooterContactUsLinks } from "../../modules/shared/ui/hooks/use-build-footer-contact-us-links";
import { useBuildFooterMediaLinks } from "../../modules/shared/ui/hooks/use-build-footer-media-links";
import FooterAccordion from "./FooterAccordion";

export default function Footer() {
  const [currentAccordion, setCurrentAccordion] = useState("");

  const { mediaLinks: navigationSocialMediaLinks } = useBuildFooterMediaLinks();
  const { appLinks: navigationAppStores } = useBuildFooterAppLinks();
  const { contactUsLinks: navigationContactUs } =
    useBuildFooterContactUsLinks();
    
  const navigationAboutUs = [
    {
      name: "Quiénes somos",
      href: "https://informacion.hipermaxi.com/quienes-somos/",
      targetBlank: true,
    },
    {
      name: "Responsabilidad Social",
      href: "https://informacion.hipermaxi.com/category/responsabilidad-social/",
      targetBlank: true,
    },
    {
      name: "Términos y condiciones",
      href: "https://informacion.hipermaxi.com/terminos-y-condiciones-generales/",
      targetBlank: true,
    },
    {
      name: "Negocios",
      href: "https://informacion.hipermaxi.com/negocios/",
      targetBlank: true,
    },
    { name: "Sucursales", href: "/sucursales" },
    {
      name: "Proveedores",
      href: "https://portal.hipermaxi.com/",
      targetBlank: true,
    },
  ];

  return (
    <div
      className={classNames("bg-hiperorange mt-4")}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="container mx-auto flex flex-col gap-3 py-2 pb-3">
        <div className="md:grid md:grid-cols-4 gap-4">
          <div className="col-span-2 flex flex-col gap-3">
            {navigationAppStores.length > 0 && (
              <div>
                <div className="md:text-lg font-semibold text-white">
                  Descarga la App
                </div>
                <div className="mt-2 flex gap-2">
                  {navigationAppStores.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt={item.name} src={item.image} className="h-11" />
                    </a>
                  ))}
                </div>
              </div>
            )}
            {navigationSocialMediaLinks.length > 0 && (
              <div>
                <div className="md:text-lg font-semibold text-white">
                  Síguenos en redes sociales
                </div>
                <ul className="pl-0 mt-2 flex gap-2">
                  {navigationSocialMediaLinks.map((item) => (
                    <a
                      aria-label={item.name}
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                      className="w-8 h-8 flex items-center justify-center bg-white text-hiperorange rounded-full transition duration-200 ease-in-out transform hover:scale-105"
                    >
                      <span className="sr-only">{item.name}</span>
                      <FontAwesomeIcon
                        size="lg"
                        icon={item.icon}
                        aria-hidden="true"
                      />
                    </a>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="gap-4 hidden md:block">
            <div className="flex flex-col gap-1">
              <div className="text-lg font-semibold text-white">
                Sobre nosotros
              </div>
              <ul className="m-0 pl-0 space-y-1">
                {navigationAboutUs.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-sm text-white hover:underline"
                      target={item.targetBlank ? "_blank" : "_self"}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="gap-4 hidden md:block">
            <div className="flex flex-col gap-1">
              <div className="text-lg font-semibold text-white">
                Contáctanos
              </div>
              <ul className="m-0 pl-0 space-y-1">
                {navigationContactUs.map((item) => (
                  <li className="flex flex-col gap-1" key={item.name}>
                    <label className="text-white text-sm font-semibold">
                      {item.name}
                    </label>
                    <div className="text-white flex gap-2 items-center">
                      <FontAwesomeIcon
                        size="lg"
                        icon={item.icon}
                        aria-hidden="true"
                      />
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm text-white hover:underline"
                      >
                        {item.value}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-span-full flex md:hidden flex-col w-full">
            <FooterAccordion
              title="Sobre nosotros"
              isOpen={currentAccordion === "aboutUs"}
              toggleAccordion={(event) => {
                if (currentAccordion === "aboutUs") {
                  setCurrentAccordion("");
                } else {
                  setCurrentAccordion("aboutUs");
                }
              }}
            >
              <ul className="mt-2 pl-0 space-y-2">
                {navigationAboutUs.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-sm text-white hover:underline"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </FooterAccordion>
            <FooterAccordion
              title="Contáctanos"
              isOpen={currentAccordion === "contactUs"}
              toggleAccordion={(event) => {
                if (currentAccordion === "contactUs") {
                  setCurrentAccordion("");
                } else {
                  setCurrentAccordion("contactUs");
                }
              }}
            >
              <ul className="mt-2 pl-0 space-y-2">
                {navigationContactUs.map((item) => (
                  <li className="flex flex-col gap-1" key={item.name}>
                    {item.name && (
                      <label className="text-white text-sm font-semibold">
                        {item.name}
                      </label>
                    )}
                    <div className="text-white flex gap-2 items-center">
                      <FontAwesomeIcon
                        size="lg"
                        icon={item.icon}
                        aria-hidden="true"
                      />
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm text-white hover:underline"
                      >
                        {item.value}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </FooterAccordion>
          </div>
        </div>
        <div className="flex items-center justify-center text-sm text-white text-center">
          Copyright&copy;{" "}
          {`${new Date().getFullYear()} Hipermaxi SA. Todos los derechos reservados.`}
        </div>
      </div>
    </div>
  );
}
